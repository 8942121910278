import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style-sheets/AdminEditInfo.css";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { change_password } from "./../../api/index";
import SnackbarAlert from "./../snackbar/SnackbarAlert";

function Assitance({ show, setShow }) {

  const handleClose = () => {
    setShow(false);
  };
  
  const [translationEnabled, setTranslationEnabled] = useState(true);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="border border-light modalBody"
        centered
        style={{ fontFamily: "SinewsSansPro" }}
      >
        <Modal.Header className="justify-content-start modalHead">
          <Button
            variant="border border-danger text-danger bg-light ms-1"
            onClick={handleClose}
            style={{ fontFamily: "Burlingame" }}
          >
            {translationEnabled ? "chiudi" : "Close"}
          </Button>
          <Modal.Title className="mx-auto text-secondary" style={{paddingRight: "100px"}}>
          Assistenza
          </Modal.Title>
         
        </Modal.Header>
        <Modal.Body className="backColor border border-secondary">
          <div className="container" style={{ padding: "10px" }}>
            <form className="row g-3">
              <div
                className="border col-12"
                style={{
                  padding: "12px",
                  borderRadius: "12px",
                  background: "#fff",
                }}
              >
                <div className="col-12">
                  <h6>I nostri contatti</h6>
                  <p>Per qualsiasi informazione puoi contattarci ai seguenti recapiti: </p>
                  <p>Whatsapp: +39 3332516670</p>
                  <p>Telefono: +39 3332516670</p>
                  <p>Email: info@bumpsmartmenu.com</p>
                  <p>Disponibilità: 24/7</p>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Assitance;
