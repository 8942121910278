import React from "react";
import { Divider } from "@mui/material";

const Highlighter = ({ title, fontColor, isClient = false }) => {
  const restData = isClient ? JSON.parse(sessionStorage.getItem("restData")) : JSON.parse(localStorage.getItem("restData"));
  return (
    <div
      className="container-fluid text-center"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Divider
        style={{
          width: "50%",
          border: restData
            ? `1.5px solid ${restData?.themeColor}`
            : "1.5px solid #507839",
          opacity: "100%",
        }}
      />
      <span
        className="badge rounded-pill wifiBadge"
        style={{
          margin: "0 10px",
          textTransform: "uppercase",
          padding: "5px 15px",
          fontSize: "14px",
          background: restData ? restData.themeColor : "#507839",
          fontFamily: "SinewsSansPro",
          color: fontColor
        }}
      >
        {title}
      </span>
      <Divider
        style={{
          width: "50%",
          border: restData
            ? `1.5px solid ${restData?.themeColor}`
            : "1.5px solid #507839",
          opacity: "100%",
        }}
      />
    </div>
  );
};

export default Highlighter;
