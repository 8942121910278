import React, { useEffect, useState } from "react";
import Header from "./Header";
import Restaurant from "./Restaurant";
import Menu from "./MenuView";
import Footer from "./Footer";
import axios from "axios";
import { useParams } from "react-router-dom";
import { get_restaurant_details } from "../api";
import { Container } from "@mui/material";
import Loader from "./Loader";
import useMediaQuery from "@mui/material/useMediaQuery";

const allergens = [
  {
    id: 1,
    name: "celery",
    translatedName: "sedano",
    exclude: false,
  },
  {
    id: 2,
    name: "crustacens",
    translatedName: "crostacei",
    exclude: false,
  },
  {
    id: 3,
    name: "eggs",
    translatedName: "uova",
    exclude: false,
  },
  {
    id: 4,
    name: "fish",
    translatedName: "pesce",
    exclude: false,
  },
  {
    id: 5,
    name: "gluten",
    translatedName: "Glutine",
    exclude: false,
  },
  {
    id: 6,
    name: "lactose",
    translatedName: "lattosio",
    exclude: false,
  },
  {
    id: 7,
    name: "lupin",
    translatedName: "lupini",
    exclude: false,
  },
  {
    id: 8,
    name: "molluscs",
    translatedName: "molluschi",
    exclude: false,
  },
  {
    id: 9,
    name: "mustard",
    translatedName: "senape",
    exclude: false,
  },
  {
    id: 10,
    name: "peanut",
    translatedName: "arachidi",
    exclude: false,
  },
  {
    id: 11,
    name: "sesame",
    translatedName: "sesamo",
    exclude: false,
  },
  {
    id: 12,
    name: "soya",
    translatedName: "soia",
    exclude: false,
  },
  {
    id: 13,
    name: "sulphurdioxide",
    translatedName: "solfiti",
    exclude: false,
  },
  {
    id: 14,
    name: "treenut",
    translatedName: "frutta a guscio",
    exclude: false,
  },
];

const HomePage = () => {
  const params = useParams();

  const [data, setData] = useState([]);
  const [excludedAllergens, setExcludedAllergens] = useState(allergens);

  const selectedProd = JSON.parse(sessionStorage.getItem("selectedProducts"));
  const [selectedProducts, setSelectedProducts] = useState(
    selectedProd === null ? [] : selectedProd
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isActivated, setIsActivated] = useState(true);

  useEffect(() => {
    const excluded = JSON.parse(sessionStorage.getItem("excludedAllergens"));
    if (excluded !== null) {
      setExcludedAllergens(excluded);
    }
  }, [excludedAllergens?.length]);

  const matches = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${get_restaurant_details}/${params.id}`)
      .then((res) => {
        sessionStorage.setItem(
          "restData",
          JSON.stringify(res?.data?.data?.restaurants)
        );

        setData(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message == "Restaurant is not activated") {
          setIsActivated(false);
        }

        console.log(err?.response?.data?.message, "error");
        setIsLoading(false);
      });
  }, [params]);

  if (isActivated) {
    return (
      <div>
        <Loader isLoading={isLoading} />
        <Header
          {...data}
          excludedAllergens={excludedAllergens}
          setExcludedAllergens={setExcludedAllergens}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
        />

        <Restaurant {...data} />
        {!matches ? (
          <Menu
            {...data}
            excludedAllergens={excludedAllergens}
            setSelectedProducts={setSelectedProducts}
            selectedProducts={selectedProducts}
          />
        ) : (
          <Container maxWidth={"md"}>
            <Menu
              {...data}
              excludedAllergens={excludedAllergens}
              setSelectedProducts={setSelectedProducts}
              selectedProducts={selectedProducts}
            />
          </Container>
        )}

        <Footer
          {...data}
          setSelectedProducts={setSelectedProducts}
          selectedProducts={selectedProducts}
        />
      </div>
    );
  } else {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "100px",
        }}
      >
        <h2>Questo account non è più attivo</h2>
      </div>
    );
  }
};

export default HomePage;
