import React, { useState, useRef, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style-sheets/AdminPhotoUpload.css";
import axios from "axios";
import Loader from "../Loader";
import dummyImage from "../../assets/add-photo.svg";
import cameraImg from "../../assets/camera.svg";
import { admin_edit_restaurant, get_restaurant } from "./../../api/index";

import { useMediaQuery } from 'react-responsive'

function AdminPhotoUpload({
  image,
  setImage,
  isLoading,
  setIsLoading,
  handleSnackbarVal,
  getRestaurants,
}) {
  const imageRef = useRef(null);

  const [translationEnabled, setTranslationEnabled] = useState(true);

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })

  const handleImage = async (e) => {
    const restData = JSON.parse(localStorage.getItem("restData"));
    setIsLoading(true);

    const form = new FormData();
    form.append("file", e.target.files[0]);
    form.append("upload_preset", "dvh3kj9z");
    try {
      let res = await axios.post(
        "https://api.cloudinary.com/v1_1/bump-smart-menu/upload",
        form
      );
      if (res) {
        setIsLoading(false);
        setImage(res.data.secure_url);

        axios
          .put(`${admin_edit_restaurant}/${restData._id}`, {
            imageUrl: res.data.secure_url,
          })
          .then((res) => {
             setIsLoading(false);
            handleSnackbarVal(
              true,
              "success",
              "Restaurant image updated successfully"
            );
            getRestaurants();
          })
          .catch((err) => {
            console.log(err, "error");
            setIsLoading(false);
          });
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className="text-center" style={{ position: "relative" }}>
        <button
          style={{
            position: "absolute",
            left: "10px",
            top: "20px",
            display: "flex",
            alignItems: "center",
            padding: "5px",
            borderRadius: "10px",
            fontStyle: "italic",
            color: "black",
            border: "2px solid",
          }}
          onClick={() => imageRef.current.click()}
        >
          <span style={{ marginRight: "5px" }}>
            <img src={cameraImg} width="100%" />
          </span>{" "}
          {translationEnabled ? "cambia foto" :"Change Photo"}
        </button>

        <div className="restaurantLogo m-0">
          <div
            className="PhotoUpload"
            // style={{ background: `url(${image ? image : dummyImage})` }}
          >
            {isDesktopOrLaptop == true ?  <img
              src={image ? image : dummyImage}
              className={image ? "img-fluid restaurantCover" : ""}
              style={image ? {verticalAlign: "middle", borderStyle: "none", width: "100%"} : {}}
            /> :  
            <img
              src={image ? image : dummyImage}
              className={image ? "img-fluid restaurantCover" : ""}
              style={{width: "100%"}}
            />
          }
          </div>
          <input
            type="file"
            onChange={handleImage}
            style={{ visibility: "hidden" }}
            // accept="image/png, image/gif, image/jpeg"
            ref={imageRef}
          />
        </div>
      </div>
    </>
  );
}

export default AdminPhotoUpload;
