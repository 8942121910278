import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style-sheets/AdminEditInfo.css";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { admin_edit_restaurant, get_user } from "../../api";
import SnackbarAlert from "./../snackbar/SnackbarAlert";

//create your forceUpdate hook
function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
  // An function that increment 👆🏻 the previous state like here
  // is better than directly setting `value + 1`
}

function CustomLinkModal({ show, setShow }) {
  const [restData, setRestData] = useState(
    JSON.parse(localStorage.getItem("restData"))
  );
  const forceUpdate = useForceUpdate();
  const [inputValue, setInputValue] = useState({
    domain: `${window.location.host}/`,
    domainLink: "",
  });
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });
  const handleClose = () => {
    setShow(false);
  };

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  useEffect(() => {
    let res;

    setTimeout(async () => {
      setRestData(await JSON.parse(localStorage.getItem("restData")));
      res = await JSON.parse(localStorage.getItem("restData"));

      setInputValue({
        domain: `${window.location.host}/`,
        domainLink: res?.restUrl,
      });

      forceUpdate();
    }, 1000);
  }, []);

  const handleChange = (e) => {
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });
  };

  const changeRestName = (key) => {
    let newParams = {
      restUrl: inputValue.domainLink,
    };
    let regex = /^[A-Za-z0-9_.]+$/;

    if (inputValue.domainLink == "") {
      handleSnackbarVal(true, "error", "Link is required");
    } else if (!regex.test(inputValue.domainLink == "")) {
      handleSnackbarVal(true, "error", "Invalid URL");
    } else {
      axios
        .put(`${admin_edit_restaurant}/${restData?._id}`, newParams)
        .then((res) => {
          setSnackbarProps({
            open: true,
            type: "success",
            message: "Updated successfully",
          });

          setShow(false);
          localStorage.setItem("restData", JSON.stringify(res.data.data));

         
        })
        .catch((err) => {
          setSnackbarProps({
            open: true,
            message: err?.response?.data?.message,
            type: "error",
          });
          console.log(err, "Errr");
        });
    }
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  const [translationEnabled, setTranslationEnabled] = useState(true);


  return (
    <>
      <SnackbarAlert
        handleCloseSnackbar={handleCloseSnackbar}
        snackbarProps={snackbarProps}
      />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="border border-light modalBody"
        centered
      >
        <Modal.Header className="justify-content-start modalHead">
          <Button
            variant="border border-danger text-danger bg-light mx-auto ms-1"
            onClick={handleClose}
            style={{ fontFamily: "Burlingame" }}
          >
            {translationEnabled ? "chiudi" : "Close"}
          </Button>
          <Modal.Title
            className="text-secondary"
            style={{ width: "100%", textAlign: "center" }}
          >
            {translationEnabled ? "Link Personalizzato" : "Cutom Link"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="backColor border border-secondary">
          <div className="container" style={{ padding: "10px" }}>
            {translationEnabled ? (
              <h4>Personalizza la URL del tuo menu!</h4>
            ) : (
              <h4>Customize your URL menu!</h4>
            )}
            <p>
              {translationEnabled
                ? "Il link verrà salvato solo se univoco, se non compilato verrà utilizzato il link standard"
                : "The inserted link will be saved only if unique, if not filled in, the standard link will be used."}
            </p>

            <form className="row g-3">
              <div
                className="col-12"
                style={{
                  padding: "12px",
                }}
              >
                <div className="col-12" style={{ display: "flex" }}>
                  <input
                    value={inputValue.domain}
                    disabled
                    name="newPass"
                    onChange={handleChange}
                    className="form-control border border-primary"
                  />
                  <input
                    value={inputValue.domainLink}
                    name="domainLink"
                    onChange={handleChange}
                    className="form-control border border-primary"
                  />
                </div>

                <p style={{ textAlign: "center" }}>
                  {translationEnabled
                    ? "sono ammessi solo caratteri e numeri (a-z 0-9)"
                    : "only characters and numbers are allowed (a-z 0-9)"}
                </p>
              </div>
            </form>
          </div>

          <p>
            <span style={{ textDecoration: "underline", fontSize: "20px" }}>
              {translationEnabled ? "Attenzione" : "Warning"}
            </span>

            {translationEnabled
              ? "modificando il Link Personalizzato, il QR Code verrà rigenerato e dovrai scaricarlo e stamparlo nuovamente"
              : ": by modifying the Custom Link, the QR-Code will be regenerated and you will have to download and print it again."}
          </p>
          <div style={{ textAlign: "center" }}>
            <Button
              variant="border border-primary text-primary bg-light mx-auto me-1"
              onClick={changeRestName}
              style={{ fontFamily: "Burlingame" }}
            >
              {translationEnabled ? "salva" : "Save"}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CustomLinkModal;
