import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./AdminOrders.css";

import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import { Button } from "react-bootstrap";
import RefreshIcon from "@mui/icons-material/Refresh";
import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment";
import {
  admin_orders,
  admin_delete_orders,
  update_cart_status,
} from "../../api/index";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import { useStyles } from "./../../globalStyles/globalStyles";
import { Container } from "@mui/material";
import Swal from "sweetalert2";

// make a button in every item that allows user to move to next state (and previous ONLY FOR
// PROCESSING)

function AdminOrders() {
  const classes = useStyles();
  const [allOrders, setAllOrders] = useState([]);
  const [expanded, setExpanded] = React.useState(false);
  const [playing, setPlaying] = useState(false);
  const [prevLength, setPrevLength] = useState(0);

  const [audio] = useState(
    new Audio(
      "https://upload.its.com.pk/v1/fetch/file/66961b75-3fef-406e-8923-277793adae4e.mp3"
    )
  );

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);

  const getAllOrders = () => {
    const restData = JSON.parse(localStorage.getItem("restData"));
    let value = JSON.parse(localStorage.getItem("length"))
      ? JSON.parse(localStorage.getItem("length"))
      : 0;

    axios
      .get(`${admin_orders}/${restData._id}`)
      .then((response) => {
        setAllOrders(response.data.data.reverse());
        setPrevLength(response.data.data.length);
        localStorage.setItem(
          "length",
          JSON.stringify(response.data.data.length)
        );

        if (response.data.data.length > value) {
          setPlaying(!playing);
          audio.play();
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  useEffect(() => {
    getAllOrders();
    setInterval(() => {
      getAllOrders();
    }, 60000);
  }, []);

  const resetOrders = () => {
    Swal.fire({
      title: !translationEnabled ? "Are you sure you want to delete orders?" : "sei sicuro di voler cancellare gli ordini?",
      text: !translationEnabled ? "You won't be able to revert this!" : "Non potrai ripristinare questa azione!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: translationEnabled ? "Si, Cancella" : "Yes, Delete",
      cancelButtonText: translationEnabled ? "Annulla" : "Cancel"
    }).then((result) => {
      if (result.isConfirmed) {
        const restData = JSON.parse(localStorage.getItem("restData"));
        axios
          .get(`${admin_delete_orders}/${restData._id}`)
          .then((response) => {
            Swal.fire("Reset!", "Orders reset successfully.", "success");
            localStorage.setItem("length", JSON.stringify(0));
            getAllOrders();
          })
          .catch((err) => {
            console.log(err, "error");
          });
      }
    });
  };

  const [csvReport, setCsvReport] = useState({
    data: allOrders,
    filename: "Orders Report.csv",
  });

  const pushCSVData = () => {
    let tempArr = [];

    let newObj = {};
    allOrders.map((item) => {
      newObj = {
        Time: item.createdAt,
        Notes: item.notes,
        TableNo: item.tableNum,
        TotalBill: item.totalBill,
        Status: item.trackStatus,
      };
      tempArr.push(newObj);
    });
    setCsvReport({
      data: tempArr,
      filename: `Orders Report ${new Date().toDateString()}.csv`,
    });
  };

  let options = ["New Order", "Preparing", "Processed", "Paid", "Cancelled"];
  let translatedOptions = ["Nuovo Ordine", "In Preparzione", "Servito", "Pagato" ,"Annullato"]

  const handleChange = (event, val) => {
    console.log(event.target.value, val);
    axios
      .put(`${update_cart_status}/${val._id}`, {
        trackStatus: event.target.value,
      })
      .then((res) => {
        getAllOrders();
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [translationEnabled, setTranslationEnabled] = useState(true);

  return (
    <>
      <div className="modalHead">
        <div
          className="text-secondary"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Button
            variant="bg-transparent ms-1 px-1"
            style={{ textAlign: "left" }}
          >
            <Link to={"/admin"} className="text-dark">
              <img
                src="/icons/adminstrator-icons/arrow-left-back.svg"
                width={"30px"}
                alt=""
              />
            </Link>
          </Button>
          <div
            className="text-start text-secondary my-2"
            style={{ width: "100%", marginRight: "50px" }}
          >
            <p
              style={{
                textAlign: "center",
                marginBottom: 0,
                fontFamily: "SinewsSansPro",
              }}
            >
              <AssignmentOutlinedIcon className="me-2" fontSize="large" />
              {translationEnabled ? "Lista Ordini" : "ORDER LIST" }
            </p>
          </div>
        </div>
      </div>
      <div>
        <div style={{ textAlign: "center" }}>
          <Button
            variant=" ordersBackColor OrdersBtnBack curvy my-3 border border-dark"
            onClick={() => window.location.reload()}
            style={{ Width: "30px", fontFamily: "SinewsSansPro" }}
          >
            Aggiorna{" "}
            <span>
              <RefreshIcon />
            </span>
          </Button>
        </div>

        <Container maxWidth="md">
          <div>
            {allOrders?.map((order, index) => (
              <Accordion
                expanded={expanded === order._id}
                onChange={handleChangeAccordion(order._id)}
                style={{
                  backgroundColor:
                  order.trackStatus == "New Order" ? "red" : order.trackStatus == "Preparing" ? "#b6b53c" : order.trackStatus == "Cancelled" ? "#484848" : order.trackStatus == "Paid" ? "blue" : "green",
                  borderRadius: "20px",
                  marginBottom: "15px",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <div className="container-fluid text-center">
                    <div className="row mt-2">
                      <span className="mb-1 orderFont"  style={{ color: "#fff" }}>
                      {translationEnabled ? "ORDINE" : "ORDER" } # {allOrders.length - index}
                      </span>
                      <span
                        className="ordersHourFont"
                        style={{ color: "#fff" }}
                      >
                          {translationEnabled ? "Orario" : "Hour" } : {moment(order.createdAt).format("HH:mm:ss")}
                      </span>
                    </div>

                    <div
                      className="mt-1 orderTableFont"
                      style={{ color: "#fff" }}
                    >
                        {translationEnabled ? "Numero Tavolo" : "Table Number" }: {order.tableNum}
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    backgroundColor:
                      order.trackStatus == "New Order"
                        ? "pink"
                        : order.trackStatus == "Preparing"
                        ? "#cfcf66"
                        : order.trackStatus == "Cancelled" ? "gray" : order.trackStatus == "Paid" ? "#1e90ff " : "#3ab53a",
                    borderRadius: "0 0 20px 20px",
                  }}
                >
                  <div style={{ margin: "5px 10px" }}>
                    {order.products.map((item) => (
                      <div
                        className="mt-1"
                        style={{
                          color: "#fff",

                          fontFamily: "SinewsSansPro",
                        }}
                      >
                        {item.selectedQuantity} x {item.name} {(item?.price?.name?.toLowerCase() !== "price" && item?.price?.name?.toLowerCase() !== "prezzo") ? `- ${item?.price?.name}`  : ""}
                      </div>
                    ))}

                    <div
                      className="mt-1"
                      style={{ color: "#fff", fontFamily: "SinewsSansPro" }}
                    >
                      <span style={{ textDecoration: "underline" }}> {translationEnabled ? "Note" : "Notes" }</span>
                      : {order.notes}
                    </div>
                    <div className="row mt-2 justify-content-end">
                      <select
                        value={order.trackStatus}
                        onChange={(e) => handleChange(e, order)}
                        style={{
                          width: "30%",
                          color:
                            order.trackStatus == "New Order"
                              ? "red"
                              : order.trackStatus == "Preparing"
                              ? "orange"
                              : order.trackStatus == "Cancelled" ? "gray" : "#2e7d32",
                          fontFamily: "SinewsSansPro",
                        }}
                      >
                        {
                          translationEnabled ? translatedOptions.map((option, index) => (
                            <option
                              value={options[index]}
                              style={{ fontFamily: "SinewsSansPro" }}
                            >
                              {option}
                            </option>
                          ))
                         : options.map((option) => (
                            <option
                              value={`${option}`}
                              style={{ fontFamily: "SinewsSansPro" }}
                            >
                              {option}
                            </option>
                          ))
                         } 
                      </select>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
          <div
            className="text-center"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: "3px" }}>
              <CSVLink {...csvReport}>
                <Button
                  variant="bg-light text-primary border border-primary curvy"
                  onClick={() => pushCSVData()}
                  style={{ fontFamily: "SinewsSansPro" }}
                >
                  
                  {translationEnabled ? "Esporta Ordini" : "Export Orders" }
                </Button>
              </CSVLink>
            </div>
            <div>
              <Button
                variant="text-light btn-danger curvy"
                style={{ background: "red", fontFamily: "SinewsSansPro" }}
                onClick={() => resetOrders()}
              >
                
                {translationEnabled ? "Reset Ordini" : "Reset Orders" }
              </Button>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default AdminOrders;
