import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style-sheets/Share.css";

import { Button, Modal } from "react-bootstrap";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ShareRoundedIcon from "@mui/icons-material/ShareRounded";
import LinkIcon from "@mui/icons-material/Link";
import SnackbarAlert from "./snackbar/SnackbarAlert";

function Share() {
  const restData = JSON.parse(localStorage.getItem("restData"));
  const [show, setShow] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const handleCloseSnackbar = () => {
    setSnackbarProps({
      open: false,
      type: "",
      message: "",
    });
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [translationEnabled, setTranslationEnabled] = useState(true);

  useEffect(() => {
    const translation = localStorage.getItem("translationEnabled");
    if(translation == "true"){
      setTranslationEnabled(true)
    }else if(translation == "false"){
      setTranslationEnabled(false)
    }else{
      setTranslationEnabled(true)
    }
  }, []);

  return (
    <>
      <Button variant="bg-transparent" onClick={handleShow}>
        <ShareRoundedIcon className="text-primary" />
      </Button>

      <Modal show={show} onHide={handleClose} backdrop="static" centered>
        <Modal.Header className="justify-content-end bg-light">
          <SnackbarAlert
            snackbarProps={snackbarProps}
            handleCloseSnackbar={handleCloseSnackbar}
          />
          <div className="container">
            <div className="row">
              <Modal.Title
                className=" col-9 mx-auto ms-0"
                style={{ fontFamily: "SinewsSansPro" }}
              >
                {translationEnabled ? "Condividi" : "Share"}
              </Modal.Title>
              <Button variant=" col-2" onClick={handleClose}>
                <CancelOutlinedIcon className="closeIcon" />
              </Button>
            </div>

            <div
              className="row text-secondary ms-0"
              style={{ fontFamily: "SinewsSansPro" }}
            >
              {translationEnabled
                ? "Condividi il menu con chiunque"
                : "Share menu with your friends"}
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <a
              href={`https://wa.me/?text=${window.location.href}`}
              target="_blank"
              style={{ color: "black" }}
            >
              <div className="row my-1">
                <div
                  className="col-10"
                  style={{ fontFamily: "SinewsSansPro", marginBottom: "10px" }}
                >
                  <img
                    src="/icons/svg/whatsapp.svg"
                    alt=""
                    width={"40px"}
                    className="m-1 me-3"
                  />
                  Whatsapp
                </div>

                <div className="col-2 text-secondary">
                  <ArrowForwardIosIcon />
                </div>
              </div>
            </a>

            <a
              onClick={() => {
                let link = `https://${window.location.host}/${restData?.restUrl}`;
                window.navigator.clipboard.writeText(link);

                setSnackbarProps({
                  open: true,
                  type: "success",
                  message: "URL copied",
                });
              }}
              target="_blank"
            >
              <div className="row my-1">
                <div
                  className="col-10"
                  style={{
                    fontFamily: "SinewsSansPro",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      color: "black",
                      paddingLeft: "10px",
                      paddingRight: "25px",
                    }}
                  >
                    <LinkIcon />
                  </span>
                  Copia URL
                </div>

                <div className="col-2 text-secondary">
                  <ArrowForwardIosIcon />
                </div>
              </div>
            </a>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Share;
