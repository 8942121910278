import React from "react";
import { Button } from "react-bootstrap";

const StartupPage = () => {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h1>Welcome to App</h1>
      <h1>Please search for Restaurant by entering valid Url</h1>
      <div>
        {JSON.parse(localStorage.getItem("user")) ? <Button onClick={() => {window.location.href = "/admin"}}>Accedi al Menu</Button> :
        <Button onClick={() => {window.location.href = "/login"}}>Effettua il login</Button>
        }
      </div>
    </div>
  );
};

export default StartupPage;
