import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style-sheets/Categories.css";

import { Button, Modal } from "react-bootstrap";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import TableRowsRoundedIcon from "@mui/icons-material/TableRowsRounded";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import axios from "axios";
import { admin_get_section } from "../api/index";

function Categories() {
  const [show, setShow] = useState(false);
  const restData = JSON.parse(sessionStorage.getItem("restData"));
  const [allSections, setAllSections] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getSections();
  }, [restData?._id]);

  const getSections = () => {
    if (restData) {
      axios
        .get(`${admin_get_section}/${restData?._id}`)
        .then((res) => {
          setAllSections(res.data.data);
        })
        .catch((err) => {
          console.log(err, "error");
        });
    }
  };

  const categories = [
    "Beef",
    "Pizza",
    "Sushi",
    "Wine List",
    "Beverage",
    "Dessert",
  ];

  return (
    <>
      <Button variant="bg-transparent" onClick={handleShow}>
        <TableRowsRoundedIcon className="text-primary" />
      </Button>

      <Modal show={show} onHide={handleClose} backdrop="static" centered>
        <Modal.Header className="justify-content-end bg-light">
          <div className="container">
            <div className="row">
              <Modal.Title
                className=" col-9 mx-auto ms-0"
                style={{ fontFamily: "SinewsSansPro" }}
              >
                Categorie
              </Modal.Title>
              <Button variant=" col-2" onClick={handleClose}>
                <CancelOutlinedIcon className="closeIcon" />
              </Button>
            </div>

            <div
              className="row text-secondary ms-0"
              style={{ fontFamily: "SinewsSansPro" }}
            >
              Vista rapida delle categorie del menu
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            {allSections.length > 0 ? (
              allSections.map((item, index) => (
                <>
                  <div className="row my-1" onClick={handleClose}>
                    <div className="col-10">
                      <a
                        href={`#${item}`}
                        className="link-secondary"
                        style={{ fontFamily: "SinewsSansPro" }}
                      >
                        {item.picUrl &&
                        <img
                          src={item.picUrl}
                          // src={`/icons/food-icons/food-icons/food/${item.toLowerCase()}.svg`}
                          alt="404"
                          width={"10%"}
                          height={"40px"}
                          className="me-2"
                        />}
                        {item.name}
                      </a>
                    </div>

                    {/* <div className="col-2 text-secondary">
                      <ArrowForwardIosIcon />
                    </div> */}
                  </div>

                  <hr />
                </>
              ))
            ) : (
              <div>No Category Found</div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Categories;
