import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style-sheets/Restaurant.css";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useStyles } from "../globalStyles/globalStyles";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Highlighter from "./highlighter/Highlighter";
import { Container } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const restData = JSON.parse(sessionStorage.getItem("restData"));

function Restaurant(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const matches = useMediaQuery("(min-width:600px)");

  return (
    <div className="restaurant m-0">
      <section
        style={{
          width: "100%",
          color: "white",
          backgroundColor: "white",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        {props?.restaurants?.imageUrl && (
          <img
            src={props?.restaurants?.imageUrl}
            style={{
              verticalAlign: "middle",
              borderStyle: "none",
              width: "100%",
            }}
            alt="Restaurant-Logo"
          />
        )}
      </section>
    
    {
      !matches ? <> <div className="row m-2">
      <div className="text-center">
        <h4
          style={{
            fontFamily: "SinewsSansPro",
            textTransform: "capitalize",
          }}
        >
          {props?.restaurants?.name}
        </h4>
        {/* Restuarant Name Here*/}

        {/* Restaurant Biography */}
        <p style={{ fontFamily: "SinewsSansPro" }}>{restData?.biography}</p>
        <div className="restaurantCard card card-body mx-auto text-center">
          <p className="card-text" style={{ fontFamily: "SinewsSansPro" }}>
            <img
              src={"/icons/adminstrator-icons/info-restaurant/address.svg"}
              className="restIcon"
              alt="address-icon"
            />
            {props?.restaurants?.address}
          </p>
          <p className="card-text" style={{ fontFamily: "SinewsSansPro" }}>
            <img
              src={
                "/icons/adminstrator-icons/info-restaurant/telephone.svg"
              }
              className="restIcon"
              alt="phone-icon"
            />{" "}
            {props?.restaurants?.telephone}
          </p>
          <p className="card-text">
            {props?.restaurants?.instagram && (
              <a href={props?.restaurants?.instagram} target={"_blank"}>
                <img
                  src={
                    "/icons/svg/instagram.svg"
                  }
                  className="infoIcon"
                  alt="Insta"
                />
              </a>
               
            )}
            {props?.restaurants?.facebook && (
              <a href={props?.restaurants?.facebook} target={"_blank"}>
                <img
                  src={
                    "/icons/svg/facebook.svg"
                  }
                  className="infoIcon"
                  alt="fb"
                />
              </a>
            )}
            {props?.restaurants?.whatsapp && (
              <a
                href={
                  "https://wa.me/" +
                  props?.restaurants?.whatsapp?.replace("+", "")
                }
                target={"_blank"}
              >
                <img
                  src={
                    "/icons/svg/whatsapp.svg"
                  }
                  className="infoIcon"
                  alt="whatsapp"
                />
              </a>
            )}
            {props?.restaurants?.tripadvisor && (
              <a href={props?.restaurants?.tripadvisor} target={"_blank"}>
                <img
                  src={
                    "/icons/adminstrator-icons/info-restaurant/tripadvisor-logo.png"
                  }
                  className="infoIcon"
                  alt="tripadvisor"
                />
              </a>
            )}
          </p>
        </div>
      </div>
    </div>

    {restData && restData.isWifiAvailable == "false" ? (
      <>
        <div className="row m-5" id="wifi">
          <Highlighter title="Wi-Fi" fontColor = {
                        restData && restData.fontColor
                          ? restData.fontColor
                          : "#ffff" } isClient={true} />
        </div>
        <div className="row mx-auto" style={{ margin: "10px 0" }}>
          <div>
            <Accordion
              expanded={expanded === "wifi"}
              onChange={handleChange("wifi")}
              className={classes.customCollapse}
              style={{
                backgroundColor:
                  props?.restaurants && props?.restaurants?.themeColor
                    ? props?.restaurants?.themeColor
                    : "#507839",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <div
                  className="container justify-content-start"
                  style={{
                    textTransform: "uppercase",
                    fontFamily: "SinewsSansPro",
                    color:
                      restData && restData.fontColor
                        ? restData.fontColor
                        : "#ffff",
                  }}
                >
                  Wi-fi Gratuito
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="p-2">
                  <div className="col">
                    <img
                      src={"/icons/wi-fi-icon/wifi.svg"}
                      className="w-25 wifiIcon"
                      alt=""
                      style={{
                        border: "1px solid #00000020",
                        borderRadius: "10px",
                      }}
                    />
                  </div>

                  <div className="col">
                    <strong style={{ fontFamily: "SinewsSansPro" }}>
                      Servizio Gratuito
                    </strong>
                    <p
                      className="wifiCredentials"
                      style={{ fontFamily: "SinewsSansPro" }}
                    >
                      LINEA: {restData?.wifi_name}
                    </p>
                    <p
                      className="wifiCredentials"
                      style={{ fontFamily: "SinewsSansPro" }}
                    >
                      PASSWORD: {restData?.wifi_password}
                    </p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </>
    ) : null}</> : <>
     <Container maxWidth="md">
        <div className="row m-3">
          <div className="text-center">
            <h4
              style={{
                fontFamily: "SinewsSansPro",
                textTransform: "capitalize",
              }}
            >
              {props?.restaurants?.name}
            </h4>
            {/* Restuarant Name Here*/}

            {/* Restaurant Biography */}
            <p style={{ fontFamily: "SinewsSansPro" }}>{restData?.biography}</p>
            <div className="restaurantCard card card-body mx-auto text-center">
              <p className="card-text" style={{ fontFamily: "SinewsSansPro" }}>
                <img
                  src={"/icons/adminstrator-icons/info-restaurant/address.svg"}
                  className="restIcon"
                  alt="address-icon"
                />
                {props?.restaurants?.address}
              </p>
              <p className="card-text" style={{ fontFamily: "SinewsSansPro" }}>
                <img
                  src={
                    "/icons/adminstrator-icons/info-restaurant/telephone.svg"
                  }
                  className="restIcon"
                  alt="phone-icon"
                />{" "}
                {props?.restaurants?.telephone}
              </p>
              <p className="card-text">
                {props?.restaurants?.instagram && (
                  <a href={props?.restaurants?.instagram} target={"_blank"}>
                    <img
                      src={
                        "/icons/adminstrator-icons/info-restaurant/instagram-logo.png"
                      }
                      className="infoIcon"
                      alt="Insta"
                    />
                  </a>
                )}
                {props?.restaurants?.facebook && (
                  <a href={props?.restaurants?.facebook} target={"_blank"}>
                    <img
                      src={
                        "/icons/adminstrator-icons/info-restaurant/facebook-logo.png"
                      }
                      className="infoIcon"
                      alt="fb"
                    />
                  </a>
                )}
                {props?.restaurants?.whatsapp && (
                  <a
                    href={
                      "https://wa.me/" +
                      props?.restaurants?.whatsapp?.replace("+", "")
                    }
                    target={"_blank"}
                  >
                    <img
                      src={
                        "/icons/adminstrator-icons/info-restaurant/whatsapp-logo.png"
                      }
                      className="infoIcon"
                      alt="whatsapp"
                    />
                  </a>
                )}
                {props?.restaurants?.tripadvisor && (
                  <a href={props?.restaurants?.tripadvisor} target={"_blank"}>
                    <img
                      src={
                        "/icons/adminstrator-icons/info-restaurant/tripadvisor-logo.png"
                      }
                      className="infoIcon"
                      alt="tripadvisor"
                    />
                  </a>
                )}
              </p>
            </div>
          </div>
        </div>

        {restData && restData.isWifiAvailable == "false" ? (
          <>
            <div className="row m-5" id="wifi">
              <Highlighter title="Wi-Fi" />
            </div>
            <div className="row mx-auto" style={{ margin: "10px 0" }}>
              <div>
                <Accordion
                  expanded={expanded === "wifi"}
                  onChange={handleChange("wifi")}
                  className={classes.customCollapse}
                  style={{
                    backgroundColor:
                      props?.restaurants && props?.restaurants?.themeColor
                        ? props?.restaurants?.themeColor
                        : "#507839",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <div
                      className="container justify-content-start"
                      style={{
                        textTransform: "uppercase",
                        fontFamily: "SinewsSansPro",
                        color:
                          restData && restData.fontColor
                            ? restData.fontColor
                            : "#ffff",
                      }}
                    >
                      Wi-fi Gratuito
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="p-2">
                      <div className="col">
                        <img
                          src={"/icons/wi-fi-icon/wifi.svg"}
                          className="w-25 wifiIcon"
                          alt=""
                          style={{
                            border: "1px solid #00000020",
                            borderRadius: "10px",
                          }}
                        />
                      </div>

                      <div className="col">
                        <strong style={{ fontFamily: "SinewsSansPro" }}>
                          Servizio Gratuito
                        </strong>
                        <p
                          className="wifiCredentials"
                          style={{ fontFamily: "SinewsSansPro" }}
                        >
                          LINEA: {restData?.wifi_name}
                        </p>
                        <p
                          className="wifiCredentials"
                          style={{ fontFamily: "SinewsSansPro" }}
                        >
                          PASSWORD: {restData?.wifi_password}
                        </p>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </>
        ) : null}
      </Container>
    </>
    }
       
    </div>
  );
}

export default Restaurant;
