import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style-sheets/MenuView.css";
import AddToCart from "./AddToCart";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useStyles } from "../globalStyles/globalStyles";
import Highlighter from "./highlighter/Highlighter";
import { admin_get_product } from "../api";
import axios from "axios";
import { Grid, Button, CircularProgress, Tooltip } from "@mui/material";
import SnackbarAlert from "./snackbar/SnackbarAlert";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import useMediaQuery from "@mui/material/useMediaQuery";

import translate from "translate-google-api";

const allergensObj = {
  celery: "sedano",
  crustacens: "crostacei",
  eggs: "uova",
  fish: "pesce",
  gluten: "Glutine",
  lactose: "lattosio",
  lupin: "lupini",
  molluscs: "molluschi",
  mustard: "senape",
  peanut: "arachidi",
  sesame: "sesamo",
  soya: "soia",
  sulphurdioxide: "solfiti",
  treenut: "solfiti",
};

function Menu(props) {
  const classes = useStyles();
  let { selectedProducts, setSelectedProducts } = props;
  const [expanded, setExpanded] = React.useState(false);
  const [productsData, setAllProductsData] = useState([]);
  const [productsLoading, setProductsLoading] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [show, setShow] = useState(false);
  const restaurantData = JSON.parse(sessionStorage.getItem("restData"));

  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [open, setOpen] = useState(false);
  const [activeItem, setActiveItem] = useState("");
  const [openSpicy, setOpenSpicy] = useState(false);
  const [openFrozen, setOpenFrozen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  const [translationEnabled, setTranslationEnabled] = useState(true);
  const matches = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    const translation = localStorage.getItem("translationEnabled");
    if (translation == "true") {
      setTranslationEnabled(true);
    } else if (translation == "false") {
      setTranslationEnabled(false);
    } else {
      setTranslationEnabled(true);
    }
  }, []);

  const handleDecrease = (i) => {
    let tempArr = [...selectedProducts];

    tempArr = tempArr.filter((item, ind) => ind != i);

    setSelectedProducts(tempArr);
    sessionStorage.setItem("selectedProducts", JSON.stringify(tempArr));
  };

  const handleSelectProducts = (item, i, isAllergen, price) => {
    let params = {
      selectedQuantity: 1,
      ...item,
      price: price,
    };

    setDialogData({
      item,
      ind: i,
      price,
    });

    const tempSelectedProd = JSON.parse(sessionStorage.getItem("selectedProducts"));
    let tempArr = [];

    console.log(tempSelectedProd)

    if(tempSelectedProd){
      tempArr = [...tempSelectedProd];
    }

    let newArrProducts = [...productsData];

    let res = selectedProducts.find(
      (obj) =>
        obj._id == item._id &&
        obj.price.price == price.price &&
        obj.price.name == price.name
    );

    if (res) {
      setSnackbarProps({
        open: true,
        type: "error",
        message: "Item already added to cart",
      });
    } else {
      if (isAllergen)
        setSnackbarProps({
          open: true,
          type: "error",
          message: "This products contains allergen.",
        });
      else {
        tempArr.push(params);
        setSelectedProducts(tempArr);
        setAllProductsData(newArrProducts);

        sessionStorage.setItem("selectedProducts", JSON.stringify(tempArr));

        setSnackbarProps({
          open: true,
          type: "success",
          message: "Prodotto aggiunto al tuo ordine.",
        });
      }
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [sectionData, setSectionData] = useState({});
  const [allProducts, setAllProducts] = useState([]);

  const getProducts = (item) => {
    setSectionData(item);
    if (expanded !== null) {
      setProductsLoading(true);
      axios
        .get(`${admin_get_product}/${item?._id}`)
        .then((res) => {
          setProductsLoading(false);

          let tempArr = [];

          res.data.data.map((item, i) => {
            let { productAllergens } = item;
            let newArr = [];
            productAllergens.map((val) => {
              newArr.push({
                ...val,
                id: Math.floor(Math.random() * 899999 + 100000),
              });
            });
            tempArr.push({ ...item, productAllergens: newArr });
          });

          setAllProducts(tempArr);
        })
        .catch((err) => {
          setProductsLoading(false);
          console.log(err, "error");
          setSnackbarProps({
            open: true,
            message: err?.response?.data?.message,
            type: "error",
          });
        });
    }
  };

  const handleRenderProducts = () => {
    const renderDetailsItem = (inner, index) => {
      let tempArr = [];
      let responsee = props.excludedAllergens.map((item) => {
        if (item.exclude == true) {
          tempArr.push(item.name);
        }
      });

      let result = inner.productAllergens.some((item) =>
        tempArr.includes(item.name)
      );

      return (
        <Grid
          container
          style={{
            display: inner.isShowProduct ? "flex" : "none",
          }}
        >
          {result ? (
            <Grid item xs={12} style={{ padding: "5px", opacity: "0.4" }}>
              <div
                className="card-body"
                style={{
                  display: "flex",
                  padding: 0,
                  paddingBottom: "10px",
                }}
              >
                {inner.productUrl && (
                  <a
                    style={{
                      padding: "4px",
                      display: "inline-block",
                      position: "relative",
                      width: "auto",
                      maxWidth: "100%",
                      backgroundColor: "transparent",
                      border: "1px solid #ddd",
                      height: "max-content",
                      borderRadius: "5px",
                      marginRight: "10px",
                    }}
                  >
                    <img
                      src={inner.productUrl}
                      style={{
                        width: "75px",
                      }}
                    />
                  </a>
                )}

                <div style={{ width: "100%" }}>
                  <h5
                    style={{
                      fontFamily: "SinewsSansPro",
                      color: "#555555",
                      fontWeight: "bold",
                      marginBottom: "0px",
                      wordBreak: "break-word"
                    }}
                  >
                    <strong> {inner.name}</strong>
                  </h5>
                  <p
                    className="card-text productDescription"
                    style={{
                      fontFamily: "SinewsSansPro",
                      fontSize: "14px",
                      marginBottom: "0px",
                    }}
                  >
                    {inner.desc}
                  </p>

                  <span
                    className="card-text"
                    style={{ marginBottom: "0px !important" }}
                  >
                    {inner.productAllergens.map((allergy) => {
                      return (
                        <Tooltip
                          title={
                            translationEnabled
                              ? allergensObj[allergy.name]
                              : allergy.name
                          }
                          style={{ textTransform: "capitalize" }}
                        >
                          <img
                            src={`/icons/allergen-icons/allergens-yellow/${allergy.name}.svg`}
                            alt={allergy}
                            style={{ marginRight: "3px" }}
                            width={"20px"}
                          />
                        </Tooltip>
                      );
                    })}
                  </span>
                  <p
                    className="card-text"
                    style={{
                      marginBottom: "0px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {inner?.productType?.spicy ? (
                      <Tooltip
                        title={translationEnabled ? "Piccante" : "Spicy"}
                        style={{ textTransform: "capitalize" }}
                      >
                        <img
                          src={`/icons/allergen-icons/spicy.svg`}
                          alt={"allergen"}
                          width={"15px"}
                          style={{ marginRight: "7px", marginLeft: "3px" }}
                        />
                      </Tooltip>
                    ) : null}

                    {inner?.productType?.frozen ? (
                      <Tooltip
                        title={translationEnabled ? "Surgelato" : "Frozen"}
                        style={{ textTransform: "capitalize" }}
                      >
                        <img
                          src={`/icons/allergen-icons/frozen.svg`}
                          alt={"image"}
                          className="m-1"
                          width={"15px"}
                        />
                      </Tooltip>
                    ) : null}
                  </p>

                  <div className="card-text" style={{ marginTop: "8px" }}>
                    {inner?.price?.map((val, ind) => {
                      let isIncludedInCart = false;
                      let selectedIndex = -1;

                      for (let i = 0; i < selectedProducts?.length; i++) {
                        if (
                          selectedProducts[i]?.price?.name === val.name &&
                          selectedProducts[i]?._id === inner?._id
                        ) {
                          isIncludedInCart = true;
                          selectedIndex = i;
                          break;
                        }
                      }

                      if (val.price !== 0.0) {
                        if (!matches)
                          return (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "5px",
                              }}
                            >
                              <span
                                style={{
                                  fontFamily: "SinewsSansPro",
                                  // textTransform: "capitalize",
                                  fontSize: "13px",
                                  marginRight: "8px",
                                }}
                              >
                                <strong>{val.name}</strong>
                              </span>
                              <span
                                style={{
                                  fontFamily: "SinewsSansPro",
                                  fontSize: "13px",
                                  display: "flex",
                                }}
                              >
                                <strong>
                                  {val.price &&
                                    parseFloat(val.price).toFixed(2)}{" "}
                                  {restaurantData?.currency}
                                </strong>
                                {restaurantData?.orderFromTable != "false" ? (
                                  isIncludedInCart ? (
                                    <>
                                      {" "}
                                      <Button
                                        id={inner["restName"] + inner["dish"]}
                                        style={{
                                          marginLeft: "10px",
                                          cursor: "pointer",
                                          height: "20px",
                                          borderRadius: "100px",
                                          color: "gray",
                                          fontWeight: "540",
                                          border: "1px solid red !important",
                                          borderColor: "red",
                                          width: "50px",
                                          fontSize: "13px",
                                          height: "18px",
                                          display: "flex",
                                          alignItems: "center",
                                          fontFamily: "SinewsSansPro",
                                        }}
                                        onClick={() =>
                                          handleDecrease(selectedIndex)
                                        }
                                        className=""
                                        variant="outlined"
                                        endIcon={
                                          <RemoveCircleIcon
                                            style={{
                                              color: "red",
                                              fontSize: "13px",
                                            }}
                                          />
                                        }
                                      >
                                        {!translationEnabled ? "REM" : "RIM"}
                                      </Button>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <Button
                                        id={inner["restName"] + inner["dish"]}
                                        style={{
                                          marginLeft: "10px",
                                          cursor: "pointer",
                                          height: "20px",
                                          borderRadius: "100px",
                                          backgroundColor: "rgb(235, 231, 231)",
                                          color: "gray",
                                          fontWeight: "540",
                                          border: "none !important",
                                          width: "50px",
                                          fontSize: "13px",
                                          height: "18px",
                                          display: "flex",
                                          alignItems: "center",
                                          fontFamily: "SinewsSansPro",
                                        }}
                                        onClick={() =>
                                          handleSelectProducts(
                                            inner,
                                            index,
                                            false,
                                            val
                                          )
                                        }
                                        className="border borderRound modalHead"
                                        variant="outlined"
                                        endIcon={
                                          <AddCircleIcon
                                            style={{
                                              color: "green",
                                              fontSize: "13px",
                                            }}
                                          />
                                        }
                                      >
                                        {translationEnabled ? "AGG" : "ADD"}
                                      </Button>
                                    </>
                                  )
                                ) : null}
                              </span>
                            </div>
                          );
                        else
                          return (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "5px",
                              }}
                            >
                              <span
                                style={{
                                  // marginRight: "20px",
                                  fontFamily: "SinewsSansPro",
                                  // textTransform: "capitalize",
                                  fontSize: "14px",
                                }}
                              >
                                <strong>{val.name}</strong>
                              </span>
                              <span
                                style={{
                                  fontFamily: "SinewsSansPro",
                                  fontSize: "14px",
                                  display: "flex",
                                }}
                              >
                                {/* {val.name}{" "} */}
                                <strong>
                                  {val.price &&
                                    parseFloat(val.price).toFixed(2)}{" "}
                                  {restaurantData?.currency}
                                </strong>
                                {restaurantData?.orderFromTable != "false" ? (
                                  isIncludedInCart ? (
                                    <>
                                      {" "}
                                      <Button
                                        id={inner["restName"] + inner["dish"]}
                                        style={{
                                          marginLeft: "10px",
                                          cursor: "pointer",
                                          height: "18px",
                                          borderRadius: "100px",
                                          color: "gray",
                                          fontWeight: "540",
                                          border: "1px solid red !important",
                                          borderColor: "red",
                                          fontFamily: "SinewsSansPro",
                                          width: "90px",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          fontSize: "14px",
                                        }}
                                        onClick={() =>
                                          handleDecrease(selectedIndex)
                                        }
                                        className=""
                                        variant="outlined"
                                        endIcon={
                                          <RemoveCircleIcon
                                            style={{
                                              color: "red",
                                              fontSize: "15px",
                                              marginLeft: "-5px",
                                            }}
                                          />
                                        }
                                      >
                                        {translationEnabled
                                          ? "RIMUOVI"
                                          : "REMOVE"}
                                      </Button>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <Button
                                        id={inner["restName"] + inner["dish"]}
                                        style={{
                                          marginLeft: "10px",
                                          cursor: "pointer",
                                          height: "20px",
                                          borderRadius: "100px",
                                          backgroundColor: "rgb(235, 231, 231)",
                                          color: "gray",
                                          fontWeight: "540",
                                          border: "none !important",
                                          width: "90px",
                                          fontFamily: "SinewsSansPro",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          fontSize: "14px",
                                          paddingLeft: translationEnabled
                                            ? "10px"
                                            : "",
                                        }}
                                        onClick={() =>
                                          handleSelectProducts(
                                            inner,
                                            index,
                                            false,
                                            val
                                          )
                                        }
                                        className="border borderRound modalHead"
                                        variant="outlined"
                                        endIcon={
                                          <AddCircleIcon
                                            style={{
                                              color: "green",
                                              fontSize: "15px",
                                              marginLeft: "-5px",
                                              marginRight: translationEnabled
                                                ? ""
                                                : "-4px",
                                            }}
                                          />
                                        }
                                      >
                                        {translationEnabled
                                          ? "AGGIUNGI"
                                          : "ADD"}
                                      </Button>
                                    </>
                                  )
                                ) : null}
                              </span>
                            </div>
                          );
                      }
                    })}
                  </div>
                </div>
              </div>
            </Grid>
          ) : (
            <Grid item xs={12} style={{ padding: "5px" }}>
              <div
                className="card-body"
                style={{
                  display: "flex",
                  padding: 0,
                  paddingBottom: "10px",
                }}
              >
                {inner.productUrl && (
                  <a
                    style={{
                      padding: "4px",
                      display: "inline-block",
                      position: "relative",
                      width: "auto",
                      maxWidth: "100%",
                      backgroundColor: "transparent",
                      border: "1px solid #ddd",
                      height: "max-content",
                      borderRadius: "5px",
                      marginRight: "10px",
                    }}
                  >
                    <img
                      src={inner.productUrl}
                      style={{
                        width: "75px",
                      }}
                    />
                  </a>
                )}

                <div style={{ width: "100%" }}>
                  <h5
                    style={{
                      fontFamily: "SinewsSansPro",
                      color: "#555555",
                      fontWeight: "bold",
                      marginBottom: "0px",
                      wordBreak: "break-word"
                    }}
                  >
                    <strong> {inner.name}</strong>
                  </h5>
                  <p
                    className="card-text productDescription"
                    style={{
                      fontFamily: "SinewsSansPro",
                      fontSize: "14px",
                      marginBottom: "0px",
                    }}
                  >
                    {inner.desc}
                  </p>

                  <span
                    className="card-text"
                    style={{ marginBottom: "0px !important" }}
                  >
                    {inner.productAllergens.map((allergy) => {
                      return (
                        <Tooltip
                          title={
                            translationEnabled
                              ? allergensObj[allergy.name]
                              : allergy.name
                          }
                          style={{ textTransform: "capitalize" }}
                        >
                          <img
                            src={`/icons/allergen-icons/allergens-yellow/${allergy.name}.svg`}
                            alt={allergy}
                            style={{ marginRight: "3px" }}
                            width={"20px"}
                          />
                        </Tooltip>
                      );
                    })}
                  </span>
                  <p
                    className="card-text"
                    style={{
                      marginBottom: "0px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {inner?.productType?.spicy ? (
                      <Tooltip
                        title={translationEnabled ? "Piccante" : "Spicy"}
                        style={{ textTransform: "capitalize" }}
                      >
                        <img
                          src={`/icons/allergen-icons/spicy.svg`}
                          alt={"allergen"}
                          width={"15px"}
                          style={{ marginRight: "7px" }}
                        />
                      </Tooltip>
                    ) : null}

                    {inner?.productType?.frozen ? (
                      <Tooltip
                        title={translationEnabled ? "Surgelato" : "Frozen"}
                        style={{ textTransform: "capitalize" }}
                      >
                        <img
                          src={`/icons/allergen-icons/frozen.svg`}
                          alt={"image"}
                          className="m-1"
                          width={"15px"}
                        />
                      </Tooltip>
                    ) : null}
                  </p>

                  <div className="card-text" style={{ marginTop: "8px" }}>
                    {inner?.price?.map((val, ind) => {
                      let isIncludedInCart = false;
                      let selectedIndex = -1;

                      for (let i = 0; i < selectedProducts?.length; i++) {
                        if (
                          selectedProducts[i]?.price?.name === val.name &&
                          selectedProducts[i]?._id === inner?._id
                        ) {
                          isIncludedInCart = true;
                          selectedIndex = i;
                          break;
                        }
                      }

                      if (val.price !== 0.0) {
                        if (!matches)
                          return (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "5px",
                              }}
                            >
                              <span
                                style={{
                                  fontFamily: "SinewsSansPro",
                                  // textTransform: "capitalize",
                                  fontSize: "13px",
                                  marginRight: "8px",
                                }}
                              >
                                <strong>{val.name}</strong>
                              </span>
                              <span
                                style={{
                                  fontFamily: "SinewsSansPro",
                                  fontSize: "13px",
                                  display: "flex",
                                }}
                              >
                                <strong>
                                  {val.price &&
                                    parseFloat(val.price).toFixed(2)}{" "}
                                  {restaurantData?.currency}
                                </strong>
                                {restaurantData?.orderFromTable != "false" ? (
                                  isIncludedInCart ? (
                                    <>
                                      {" "}
                                      <Button
                                        id={inner["restName"] + inner["dish"]}
                                        style={{
                                          marginLeft: "10px",
                                          cursor: "pointer",
                                          height: "20px",
                                          borderRadius: "100px",
                                          color: "gray",
                                          fontWeight: "540",
                                          border: "1px solid red !important",
                                          borderColor: "red",
                                          width: "50px",
                                          fontSize: "13px",
                                          height: "18px",
                                          display: "flex",
                                          alignItems: "center",
                                          fontFamily: "SinewsSansPro",
                                        }}
                                        onClick={() =>
                                          handleDecrease(selectedIndex)
                                        }
                                        className=""
                                        variant="outlined"
                                        endIcon={
                                          <RemoveCircleIcon
                                            style={{
                                              color: "red",
                                              fontSize: "13px",
                                            }}
                                          />
                                        }
                                      >
                                        {!translationEnabled ? "REM" : "RIM"}
                                      </Button>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <Button
                                        id={inner["restName"] + inner["dish"]}
                                        style={{
                                          marginLeft: "10px",
                                          cursor: "pointer",
                                          height: "20px",
                                          borderRadius: "100px",
                                          backgroundColor: "rgb(235, 231, 231)",
                                          color: "gray",
                                          fontWeight: "540",
                                          border: "none !important",
                                          width: "50px",
                                          fontSize: "13px",
                                          height: "18px",
                                          display: "flex",
                                          alignItems: "center",
                                          fontFamily: "SinewsSansPro",
                                        }}
                                        onClick={() =>
                                          handleSelectProducts(
                                            inner,
                                            index,
                                            false,
                                            val
                                          )
                                        }
                                        className="border borderRound modalHead"
                                        variant="outlined"
                                        endIcon={
                                          <AddCircleIcon
                                            style={{
                                              color: "green",
                                              fontSize: "13px",
                                            }}
                                          />
                                        }
                                      >
                                        {translationEnabled ? "AGG" : "ADD"}
                                      </Button>
                                    </>
                                  )
                                ) : null}
                              </span>
                            </div>
                          );
                        else
                          return (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "5px",
                              }}
                            >
                              <span
                                style={{
                                  // marginRight: "20px",
                                  fontFamily: "SinewsSansPro",
                                  // textTransform: "capitalize",
                                  fontSize: "14px",
                                }}
                              >
                                <strong>{val.name}</strong>
                              </span>
                              <span
                                style={{
                                  fontFamily: "SinewsSansPro",
                                  fontSize: "14px",
                                  display: "flex",
                                }}
                              >
                                {/* {val.name}{" "} */}
                                <strong>
                                  {val.price &&
                                    parseFloat(val.price).toFixed(2)}{" "}
                                  {restaurantData?.currency}
                                </strong>
                                {restaurantData?.orderFromTable != "false" ? (
                                  isIncludedInCart ? (
                                    <>
                                      {" "}
                                      <Button
                                        id={inner["restName"] + inner["dish"]}
                                        style={{
                                          marginLeft: "10px",
                                          cursor: "pointer",
                                          height: "18px",
                                          borderRadius: "100px",
                                          color: "gray",
                                          fontWeight: "540",
                                          border: "1px solid red !important",
                                          borderColor: "red",
                                          fontFamily: "SinewsSansPro",
                                          width: "90px",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          fontSize: "14px",
                                        }}
                                        onClick={() =>
                                          handleDecrease(selectedIndex)
                                        }
                                        className=""
                                        variant="outlined"
                                        endIcon={
                                          <RemoveCircleIcon
                                            style={{
                                              color: "red",
                                              fontSize: "15px",
                                              marginLeft: "-5px",
                                            }}
                                          />
                                        }
                                      >
                                        {translationEnabled
                                          ? "RIMUOVI"
                                          : "REMOVE"}
                                      </Button>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <Button
                                        id={inner["restName"] + inner["dish"]}
                                        style={{
                                          marginLeft: "10px",
                                          cursor: "pointer",
                                          height: "20px",
                                          borderRadius: "100px",
                                          backgroundColor: "rgb(235, 231, 231)",
                                          color: "gray",
                                          fontWeight: "540",
                                          border: "none !important",
                                          width: "90px",
                                          fontFamily: "SinewsSansPro",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          fontSize: "14px",
                                          paddingLeft: translationEnabled
                                            ? "10px"
                                            : "",
                                        }}
                                        onClick={() =>
                                          handleSelectProducts(
                                            inner,
                                            index,
                                            false,
                                            val
                                          )
                                        }
                                        className="border borderRound modalHead"
                                        variant="outlined"
                                        endIcon={
                                          <AddCircleIcon
                                            style={{
                                              color: "green",
                                              fontSize: "15px",
                                              marginLeft: "-5px",
                                              marginRight: translationEnabled
                                                ? ""
                                                : "-4px",
                                            }}
                                          />
                                        }
                                      >
                                        {translationEnabled
                                          ? "AGGIUNGI"
                                          : "ADD"}
                                      </Button>
                                    </>
                                  )
                                ) : null}
                              </span>
                            </div>
                          );
                      }
                    })}
                  </div>
                </div>
              </div>
            </Grid>
          )}
        </Grid>
      );
    };

    return (
      allProducts.length > 0 &&
      allProducts.map((inner, index) => {
        return renderDetailsItem(inner, index);
      })
    );
  };

  const handleCloseSnackbar = () => {
    setSnackbarProps({
      open: false,
      type: "",
      message: "",
    });
  };

  return (
    <div className="container-fluid" style={{marginBottom: "100px"}}>
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />

      <div className="row m-5" id="wifi">
        <Highlighter title="Menu" fontColor = {
                        restaurantData && restaurantData.fontColor
                          ? restaurantData.fontColor
                          : "#ffff" } isClient={true} />
      </div>

      <div id="menuDropdown">
        {props?.sections?.map((item, index) => (
          <div key={item.category} style={{ margin: "10px 0" }}>
            <div>
              <Accordion
                expanded={expanded === item.name}
                onChange={handleChange(item.name)}
                className={classes.customCollapse}
                style={{
                  backgroundColor:
                    restaurantData && restaurantData.themeColor
                      ? restaurantData.themeColor
                      : "#507839",
                      height: "100%"
                }}
              >
                <AccordionSummary
                  onClick={() => getProducts(item)}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <div
                    // className="container justify-content-start"
                    style={{
                      fontFamily: "SinewsSansPro",
                      color:
                        restaurantData && restaurantData.fontColor
                          ? restaurantData.fontColor
                          : "#ffff",
                      display: "flex",
                      alignItems: "center",
                      height: "100%"
                    }}
                  >
                   <span style={{display: "flex", alignItems: "center"}}>
                   {item.picUrl && (
                      <img
                        src={item.picUrl}
                        className="m-1 ms-2 me-3"
                        width={"35px"}
                        height={"40px"}
                      />
                    )}
                    </span>
                    <span>{item.name}</span>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {productsLoading ? (
                    <div style={{ height: "200px", textAlign: "center" }}>
                      <CircularProgress color="secondary" />
                    </div>
                  ) : (
                    handleRenderProducts()
                  )}
                </AccordionDetails>
              </Accordion>

              {/* <AddToCart
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                setAllProductsData={setAllProductsData}
                productsData={productsData}
                show={show}
                setShow={setShow}
                setDialogData={setDialogData}
                dialogData={dialogData}
              /> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Menu;
