import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import EditIcon from "@mui/icons-material/Edit";
import AdminEditMenu from "./AdminEditMenu";
import AdminAddProduct from "./AdminAddProduct";
import AdminAddSection from "./AdminAddSection";
import Highlighter from "./../highlighter/Highlighter";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style-sheets/AdminMenuView.css";
import {
  admin_get_section,
  admin_get_product,
  admin_delete_product,
  admin_edit_product,
  admin_sort_section,
  admin_sort_product,
} from "../../api/index";
import axios from "axios";
import { useStyles } from "../../globalStyles/globalStyles";
import Loader from "../Loader";
import { Button } from "react-bootstrap";
import { Grid, CircularProgress, Container } from "@mui/material";
import SnackbarAlert from "../snackbar/SnackbarAlert";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import Swal from "sweetalert2";
import SortIcon from "@mui/icons-material/Sort";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";

function Menu(restName, menuItems) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [productsLoading, setProductsLoading] = useState(false);
  const [sectionData, setSectionData] = useState({});
  const [allSections, setAllSections] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [dialogData, setDialogData] = useState();
  const [showEditModal, setShowEditModal] = useState(false);
  const userData = JSON.parse(localStorage.getItem("user"));
  const restData = JSON.parse(localStorage.getItem("restData"));
  const [type, setType] = useState("add");
  const [image, setImage] = useState(null);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [sectionId, setSectionId] = useState(null);

  const [translationEnabled, setTranslationEnabled] = useState(true);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    getSections();
  }, [restData?._id]);

  const getSections = () => {
    if (restData) {
      axios
        .get(`${admin_get_section}/${restData?._id}`)
        .then((res) => {
          setIsLoading(false);
          setAllSections(res.data.data);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err, "error");
        });
    }
  };

  const getProducts = (item) => {
    setSectionData(item);
    if (expanded !== null) {
      setProductsLoading(true);
      axios
        .get(`${admin_get_product}/${item?._id}`)
        .then((res) => {
          setProductsLoading(false);
          setAllProducts(res.data.data);
        })
        .catch((err) => {
          setProductsLoading(false);
          console.log(err, "error");
        });
    }
  };

  const handleEdit = (item) => {
    console.log(item);
    setType("edit");
    setShowEditModal(true);
    setDialogData(item);
  };

  const handleDelete = (item) => {
    Swal.fire({
      title: translationEnabled
        ? "sei sicuro di voler cancellare gli prodotto?"
        : "Are you sure you want to delete this Product?",
      text: translationEnabled
        ? "Non potrai ripristinare questa azione!"
        : "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: translationEnabled ? "Si, Cancella" : "Yes, Delete",
      cancelButtonText: translationEnabled ? "Annulla" : "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        axios
          .delete(`${admin_delete_product}/${item._id}`)
          .then((res) => {
            setIsLoading(false);
            setSnackbarProps({
              open: true,
              type: "success",
              message: res.data.message,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          })
          .catch((err) => {
            console.log(err, "error");
            setIsLoading(false);
          });
      }
    });
  };
  const handleShowProduct = (item) => {
    axios
      .put(`${admin_edit_product}/${item._id}`, {
        isShowProduct: !item.isShowProduct,
      })
      .then((res) => {
        setIsLoading(false);
        setSnackbarProps({
          open: true,
          type: "success",
          message: "product show/hide successfully",
        });
        window.location.reload();
      })
      .catch((err) => {
        console.log(err, "error");
        setIsLoading(false);
        setSnackbarProps({
          open: true,
          message: err?.response?.data?.message,
          type: "error",
        });
      });
  };

  const handleCloseSnackbar = () => {
    setSnackbarProps({
      open: false,
      type: "",
      message: "",
    });
  };

  const handleShow = (item) => {
    setType("add");
    setShowEditModal(true);
    setImage(null);
    setSectionId(item);
  };

  // Sortable Code
  const DragHandle = sortableHandle(() => (
    <span style={{ marginRight: "2px" }}>
      <SortIcon />
    </span>
  ));
  const SortableItem = sortableElement(({ item }) => (
    <Accordion
      expanded={expanded === item.name}
      onChange={handleChange(item.name)}
      className={classes.customCollapse}
      style={{
        backgroundColor:
          restData && restData.themeColor ? restData.themeColor : "#507839",
        marginTop: "10px",
        height: "100%"
      }}
    >
      <AccordionSummary
        onClick={() => getProducts(item)}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <DragHandle />
        <div
          style={{
            color:
              restData && restData.fontColor ? restData.fontColor : "#ffff",
              display: "flex",
              alignItems: "center",
              height: "100%"
          }}
        >
          <span style={{display: "flex", alignItems: "center"}}>
          {item.picUrl && (
            <img
            src={item.picUrl}
            className="m-1 ms-2 me-3"
            width={"35px"}
            height={"40px"}
          />
          )}
          </span>
          <span>{item.name}</span>
        </div>
        <div
          style={{ marginLeft: "auto", marginRight: "10px" }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <AdminAddSection
            type="edit"
            name="Edit"
            editItem={item}
            sectionData={sectionData}
            getSections={getSections}
          />
        </div>
        <div onClick={(e) => e.stopPropagation()}>
          <AdminAddSection
            type="delete"
            name="Delete"
            editItem={item}
            sectionData={sectionData}
          />
        </div>
        <div style={{ background: "white", width: "20px" }}></div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="mt-2">
          <div style={{ textAlign: "center" }}>
            <Button
              className="border border-dark borderRound modalHead"
              variant="bg-transparent"
              onClick={() => handleShow(item)}
              style={{ padding: "0 20px", marginBottom: "20px" }}
            >
              <img
                src="/icons/adminstrator-icons/add-product-section.svg"
                className="my-1 me-2 editWifiImg"
                alt=""
              />
              <span
                className="addProductFont"
                style={{ fontFamily: "SinewsSansPro" }}
              >
                {translationEnabled ? "Agg. prodotto" : "Add Product"}
              </span>
            </Button>
          </div>
          {productsLoading ? (
            <div style={{ height: "200px", textAlign: "center" }}>
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <ProductSortableContainer
              onSortEnd={onProductSortEnd}
              useDragHandle
            >
              {allProducts.map((item, index) => {
                return (
                  <ProductSortableItem
                    key={`${item._id}`}
                    index={index}
                    item={item}
                  />
                );
              })}
            </ProductSortableContainer>
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  ));

  const SortableContainer = sortableContainer(({ children }) => {
    return (
      <div
        style={{ margin: "10px 0" }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setAllSections(arrayMoveImmutable(allSections, oldIndex, newIndex));
    let result = arrayMoveImmutable(allSections, oldIndex, newIndex);

    for (let i = 0; i < result.length; i++) {
      result[i].sequence = i + 1;
    }

    setIsLoading(true);
    axios
      .post(admin_sort_section, { records: result })
      .then((res) => {
        setIsLoading(false);
        setSnackbarProps({
          open: true,
          type: "success",
          message: "Section sorted successfully",
        });
      })
      .catch((err) => {
        console.log(err, "error");
        setIsLoading(false);
        setSnackbarProps({
          open: true,
          message: err?.response?.data?.message,
          type: "error",
        });
      });
  };

  const ProductSortableContainer = sortableContainer(({ children }) => {
    return <div>{children}</div>;
  });

  const onProductSortEnd = ({ oldIndex, newIndex }) => {
    let result = arrayMoveImmutable(allProducts, oldIndex, newIndex);

    for (let i = 0; i < result.length; i++) {
      result[i].sequence = i + 1;
    }

    setAllProducts(result);

    setIsLoading(true);
    axios
      .post(admin_sort_product, { records: result })
      .then((res) => {
        setIsLoading(false);
        setSnackbarProps({
          open: true,
          type: "success",
          message: "Product sorted successfully",
        });
      })
      .catch((err) => {
        console.log(err, "error");
        setIsLoading(false);
        setSnackbarProps({
          open: true,
          message: err?.response?.data?.message,
          type: "error",
        });
      });
  };

  const ProductSortableItem = sortableElement(({ item }) => (
    <>
      <Grid container>
        <Grid item xs={12}>
          <div className="card-body" style={{ display: "flex", padding: 0 }}>
            <span style={{ cursor: "pointer", marginRight: "10px" }}>
              <DragHandle />
            </span>

            {item.productUrl && (
              <a
                style={{
                  padding: "4px",
                  display: "inline-block",
                  position: "relative",
                  width: "auto",
                  maxWidth: "100%",
                  backgroundColor: "transparent",
                  border: "1px solid #ddd",
                  height: "max-content",
                  borderRadius: "5px",
                  marginRight: "10px",
                }}
              >
                <img
                  src={item.productUrl}
                  style={{
                    width: "75px",
                  }}
                />
              </a>
            )}
            <div style={{ width: "100%" }}>
              <h5
                style={{
                  fontFamily: "SinewsSansPro",
                  color: "#555555",
                  fontWeight: "bold",
                  marginBottom: "0px",
                  wordBreak: "break-word"
                }}
              >
                <strong>{item.name}</strong>
              </h5>

              <p
                className="card-text productDescription"
                style={{
                  fontFamily: "SinewsSansPro",
                  fontSize: "14px",
                  marginBottom: "0px",
                }}
              >
                {item.desc}
              </p>

              <p className="card-text" style={{ marginBottom: "0px" }}>
                {item.productAllergens.map((allergy) => {
                  return (
                    <img
                      src={`/icons/allergen-icons/allergens-yellow/${allergy.name}.svg`}
                      alt={allergy}
                      style={{ marginRight: "3px" }}
                      width={"20px"}
                    />
                  );
                })}
              </p>
              <p className="card-text" style={{ marginBottom: "2px" }}>
                {item?.productType?.spicy ? (
                  <img
                    src={`/icons/allergen-icons/spicy.svg`}
                    alt={type}
                    width={"15px"}
                    style={{ marginRight: "7px", marginLeft: "2px" }}
                  />
                ) : null}

                {item?.productType?.frozen ? (
                  <img
                    src={`/icons/allergen-icons/frozen.svg`}
                    alt={type}
                    className="m-1"
                    width={"15px"}
                  />
                ) : null}
              </p>

              <div className="card-text">
                {item?.price?.map((val) => {
                  if (val.price !== 0.0) {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          style={{
                            marginRight: "20px",
                            fontFamily: "SinewsSansPro",
                            fontSize: "14px",
                          }}
                        >
                          <strong> {val.name}</strong>
                        </span>
                        <span
                          style={{
                            fontFamily: "SinewsSansPro",
                            fontSize: "14px",
                          }}
                        >
                          <strong>
                            {val.price && parseFloat(val.price).toFixed(2)}
                            {restData?.currency}
                          </strong>
                        </span>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
          <div className="card-body" style={{ display: "flex" }}>
            <Button
              variant="bg-transparent"
              onClick={() => handleEdit(item)}
              className="border border-dark borderRound modalHead"
              style={{
                padding: "0 5px",
                marginTop: "5px",
                width: "150px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src="/icons/adminstrator-icons/edit.svg"
                className="my-1 me-2"
                alt=""
                width={"15px"}
              />
              <span
                className="addProductFont"
                style={{ fontFamily: "SinewsSansPro", }}
              >
                {translationEnabled ? "Modifica Prodotto" : "Edit Product"}
              </span>
            </Button>
            <Button
              variant="bg-transparent"
              onClick={() => handleDelete(item)}
              className="border border-dark borderRound modalHead"
              style={{
                padding: "0 5px",
                marginRight: "5px",
                marginLeft: "5px",
                marginTop: "5px",
                marginTop: "5px",
                width: "150px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src="/icons/adminstrator-icons/delete.svg"
                className="my-1 me-2"
                alt=""
                width={"15px"}
              />
              <span
                className="addProductFont"
                style={{ fontFamily: "SinewsSansPro" }}
              >
                {translationEnabled ? "Elimina Prodotto" : "Delete Product"}
              </span>
            </Button>
            <Button
              variant="bg-transparent"
              onClick={() => handleShowProduct(item)}
              className="border border-dark borderRound modalHead"
              style={{
                padding: "0 5px",
                marginTop: "5px",
                width: "150px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <RemoveRedEyeOutlinedIcon
                className="me-2"
                width="15%"
                style={{ width: "15px" }}
              />
              <span
                className="addProductFont"
                style={{ fontFamily: "SinewsSansPro" }}
              >
                {item.isShowProduct
                  ? translationEnabled
                    ? "Nascondi"
                    : "Hide"
                  : translationEnabled
                  ? "Mostra"
                  : "Show"}{" "}
                {translationEnabled ? "Prodotto" : "Product"}
              </span>
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  ));

  return (
    <>
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />

      <Loader isLoading={isLoading} />

      <div>
        <div className="row m-5" id="wifi">
          <Highlighter title="Menu" fontColor={restData && restData.fontColor ? restData.fontColor : "#ffff"} />
        </div>

        <div id="menuDropdown">
          <div className="row">
            <div className="row" style={{ width: "auto", margin: "0 auto" }}>
              {translationEnabled ? (
                <AdminAddSection
                  type="add"
                  name="Agg. Sezione"
                  section={allSections}
                  setSection={setAllSections}
                />
              ) : (
                <AdminAddSection
                  type="add"
                  name="Add Section"
                  section={allSections}
                  setSection={setAllSections}
                />
              )}
            </div>
          </div>
          <SortableContainer onSortEnd={onSortEnd} useDragHandle>
            {allSections.map((item, index) => (
              <SortableItem
                key={`item-${item._id}`}
                index={index}
                item={item}
              />
            ))}
          </SortableContainer>
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        {/* <AdminAddProduct name="Add new product" type="add" sectionId={item._id} /> */}
        <AdminAddProduct
          show={showEditModal}
          setShow={setShowEditModal}
          handleEdit={handleEdit}
          dialogData={dialogData}
          setDialogData={setDialogData}
          type={type}
          setType={setType}
          name={type == "add" ? "Add Product" : "Edit Product"}
          getProducts={getProducts}
          image={image}
          setImage={setImage}
          sectionId={sectionId}
          setSectionId={setSectionId}
          products={allProducts}
          setProducts={setAllProducts}
        />
      </div>
    </>
  );
}

export default Menu;
