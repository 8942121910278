import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Space, Table, Popconfirm, Button, Input } from "antd";
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";

import axios from "axios";
import {
  admin_get_all_users,
  delete_data,
  admin_login,
  update_user_notes,
  change_subscription_status,
} from "../../api";

import Loader from "../Loader";

const AdminPanel = () => {
  const [dataSource, setDataSource] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [note, setNote] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); 
  const pageSize = 20;
   
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Surname",
      dataIndex: "surname",
      key: "surname",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Business Name",
      dataIndex: "restaurant_name",
      key: "restaurant_name",
    },
    {
      title: "Restaurant URL",
      render: (_, record, index) => <span>{record?.restaurant?.restUrl}</span>,
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      render: (_, record, index) => (
        <Input
          value={record.notes}
          onChange={(e) => {
            try {
              let temp = [...dataSource];
              temp[(pageSize*(currentPage-1)) + index].notes = e.target.value;
              setDataSource(temp);
            } catch (e) {
              console.log(e);
            }
          }}
          onPressEnter={(e) => {
            setIsLoading(true);
            axios
              .put(`${update_user_notes}/${record._id}`, {
                notes: e.target.value,
              })
              .then((res) => {
                setIsLoading(false);
              })
              .catch((err) => {
                setIsLoading(false);
                console.log(err, "Errr");
              });
          }}
          onBlur={(e) => {
            setIsLoading(true);
            axios
              .put(`${update_user_notes}/${record._id}`, {
                notes: e.target.value,
              })
              .then((res) => {
                setIsLoading(false);
              })
              .catch((err) => {
                setIsLoading(false);
                console.log(err, "Errr");
              });
          }}
        />
      ),
    },
    {
      title: "Modify Menu",
      key: "action",
      render: (_, record) => (
        <a
          onClick={() => {
            localStorage.removeItem("restData");
            localStorage.setItem("user", JSON.stringify(record));
            setIsLoading(true);

            window.open(`/admin`, "_blank").focus();
            setIsLoading(false);
          }}
          style={{ cursor: "pointer" }}
        >
          <EditIcon />
        </a>
      ),
    },
    {
      title: "Enable/Disable Account",
      key: "enable_disable",
      render: (_, record, index) => (
        <a style={{ cursor: "pointer" }}>
          {record?.subscription_status == false ? (
            <LockIcon
              onClick={() => {
                setIsLoading(true);
                axios
                  .post(change_subscription_status, {
                    userID: record._id,
                    newStatus: true,
                  })
                  .then((res) => {
                    let temp = [...dataSource];
                    temp[index].subscription_status = true;
                    setDataSource(temp);
                    setIsLoading(false);
                  })
                  .catch((err) => {
                    setIsLoading(false);
                    console.log(err);
                  });
              }}
            />
          ) : (
            <LockOpenIcon
              onClick={() => {
                setIsLoading(true);
                axios
                  .post(change_subscription_status, {
                    userID: record._id,
                    newStatus: false,
                  })
                  .then((res) => {
                    let temp = [...dataSource];
                    temp[index].subscription_status = false;
                    setDataSource(temp);
                    setIsLoading(false);
                  })
                  .catch((err) => {
                    setIsLoading(false);
                    console.log(err);
                  });
              }}
            />
          )}
        </a>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle" style={{ color: "red" }}>
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record._id)}
          >
            <a>Delete</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    const user = JSON.parse(localStorage.getItem("adminData"));
    if (user?.username) {
      axios
        .post(admin_login, user)
        .then((res) => {
          getData();
        })
        .catch((err) => {
          setIsLoading(false);
          window.location = "/";
        });
    } else {
      window.location = "/";
    }
  }, []);

  const getData = () => {
    axios
      .get(admin_get_all_users)
      .then((res) => {
        setIsLoading(false);

        const restaurants = res?.data?.data?.restaurant;
        let users = res?.data?.data?.user;
        let temp = [];

        for (let i = 0; i < users.length; i++) {
          let restaurant = restaurants.find((item) => {
            if (item.adminId == users[i]._id) return true;
            else return false;
          });

          users[i].restaurant = restaurant;
          temp.push(users[i].notes);
        }

        setDataSource(users);
        setNote(temp);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err, "error");
      });
  };

  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item._id !== key);
    setDataSource(newData);
    setIsLoading(true);
    axios
      .delete(`${delete_data}/${key}`)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    setIsLoading(false);
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          padding: "50px",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
          }}
        >
          <Button
            onClick={() => {
              localStorage.clear();
              window.location = "/";
            }}
            size="large"
            type="primary"
          >
            Logout
          </Button>
        </div>
        <Table columns={columns} dataSource={dataSource} pagination={{
          pageSize: pageSize,
          current: currentPage,
          onChange: (page) => {
            setCurrentPage(page);
          }
        }} />
      </div>
    </>
  );
};

export default AdminPanel;
