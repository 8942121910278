import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style-sheets/AdminEditMenu.css";

import { Button, Modal } from "react-bootstrap";
import SnackbarAlert from "../snackbar/SnackbarAlert";
import dummyImage from "../../assets/add-photo.svg";
import cameraImg from "../../assets/camera.svg";
import {
  admin_add_section,
  admin_edit_section,
  admin_delete_section,
} from "../../api/index";
import Loader from "../Loader";
import axios from "axios";
import { Delete, Edit } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import Swal from "sweetalert2";

var arraySort = require("array-sort");

function AdminAddSection({
  name,
  type,
  editItem,
  sectionData,
  getSections,
  setSection,
  section,
}) {
  const [show, setShow] = useState(false);
  const imageRef = useRef(null);

  const handleClose = () => {
    setShow(false);
  };

  const [isLoading, setIsLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const restData = JSON.parse(localStorage.getItem("restData"));
  const [inputValue, setInputValue] = useState({
    name: "",
  });
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [image, setImage] = useState("");

  const [translationEnabled, setTranslationEnabled] = useState(true);


  const onHandleChange = (e) => {
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });
  };

  const handleShow = () => {
    let data = JSON.parse(localStorage.getItem("restData"));
    if (data) {
      setShow(true);
    } else {
      localStorage.clear();
      setSnackbarProps({
        open: true,
        type: "error",
        message: "Please Add Resturant First",
      });
    }
  };

  const handleImage = async (e) => {
    setIsLoading(true);

    const form = new FormData();
    form.append("file", e.target.files[0]);
    form.append("upload_preset", "dvh3kj9z");
    try {
      let res = await axios.post(
        "https://api.cloudinary.com/v1_1/bump-smart-menu/upload",
        form
      );
      if (res) {
        setIsLoading(false);
        setImage(res.data.secure_url);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setSnackbarProps({
        open: true,
        message: error?.response?.data?.message,
        type: "error",
      });
    }
  };

  useEffect(() => {
    setInputValue({
      name: editItem?.name,
    });
    setImage(editItem?.picUrl);
  }, [editItem]);

  const handleSection = () => {
    let name = inputValue.name;

    let params = {
      name,
      picUrl: image,
      restId: restData._id,
    };

    if (section.length == 0) {
      params.sequence = 1;
    } else {
      const result = arraySort(section, ["sequence"]);
      params.sequence = result[result.length - 1].sequence + 1;
    }

    console.log(params);

    if (typeof name == "undefined") {
      // if (name == "" || image == "") {
      setSnackbarProps({
        open: true,
        type: "error",
        message: "All fields are required",
      });
    } else {
      setIsLoading(true);
      axios
        .post(admin_add_section, params)
        .then((res) => {
          setIsLoading(false);
          setSnackbarProps({
            open: true,
            type: "success",
            message: "Section add successfully",
          });
          setInputValue({
            name: "",
            image: "",
          });
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1500);

          setSection((sections) => {
            let temp = [...sections];
            temp.push(params);

            return temp;
          });

          setShow(false);
          window.location.reload();
        })
        .catch((err) => {
          console.log(err, "error");
          setIsLoading(false);
          setSnackbarProps({
            open: true,
            message: err?.response?.data?.message,
            type: "error",
          });
        });
    }
  };

  const handleEditSection = () => {
    const { name } = inputValue;
    let params = {
      name,
      picUrl: image,
    };
    setIsLoading(true);
    axios
      .put(`${admin_edit_section}/${editItem._id}`, params)
      .then((res) => {
        setIsLoading(false);
        setSnackbarProps({
          open: true,
          type: "success",
          message: "Section updated successfully",
        });
        window.location.reload();
      })
      .catch((err) => {
        console.log(err, "error");
        setIsLoading(false);
        setSnackbarProps({
          open: true,
          message: err?.response?.data?.message,
          type: "error",
        });
      });
    setShow(false);
  };

  const hadleDeleteSection = () => {
    Swal.fire({
      title: translationEnabled ? "Sei sicuro di voler cancellare la sezione?" : "Are you sure you want to delete this Section?",
      text:  translationEnabled ? "Non potrai ripristinare questa azione!" : "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: translationEnabled ? "Si, Cancella" : "Yes, Delete",
      cancelButtonText: translationEnabled ? "Annulla" : "Cancel"
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        axios
          .delete(`${admin_delete_section}/${editItem._id}`)
          .then((res) => {
            setIsLoading(false);
            setSnackbarProps({
              open: true,
              type: "success",
              message: "Section deleted Successfully",
            });
            setTimeout(() => {
              window.location.reload();
            }, 1500);
            getSections(restData?._id);
          })
          .catch((err) => {
            console.log(err, "error");
            setIsLoading(false);
            setSnackbarProps({
              open: true,
              message: err?.response?.data?.message,
              type: "error",
            });
          });
      }
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbarProps({
      open: false,
      type: "",
      message: "",
    });
  };

  return (
    <>
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />

      <Loader isLoading={isLoading} />
      {type === "edit" ? (
        <Tooltip title="Edit Section">
          <Button variant="bg-transparent" onClick={handleShow}>
            <Edit style={{ color: "white" }} />
          </Button>
        </Tooltip>
      ) : type === "add" ? (
        <Button
          variant="bg-transparent"
          style={{ width: "100%" }}
          onClick={handleShow}
        >
          <div className="container-fluid border border-dark borderRound modalHead">
            <img
              src="/icons/adminstrator-icons/add-product-section.svg"
              className="my-1 me-2 editWifiImg"
              alt=""
            />
            <span
              className="addProductFont"
              style={{ fontFamily: "SinewsSansPro" }}
            >
              {name}
            </span>
          </div>
        </Button>
      ) : (
        type === "delete" && (
          <>
            {translationEnabled ? (
              <Tooltip title="Elimina prodotto">
                <Button
                  variant="bg-transparent"
                  onClick={hadleDeleteSection}
                  style={{ padding: 0 }}
                >
                  <Delete style={{ color: "white" }} />
                </Button>
              </Tooltip>
            ) : (
              <Tooltip title="Delete Section">
                <Button
                  variant="bg-transparent"
                  onClick={hadleDeleteSection}
                  style={{ padding: 0 }}
                >
                  <Delete style={{ color: "white" }} />
                </Button>
              </Tooltip>
            )}
          </>
        )
      )}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="border border-light modalBody"
        centered
      >
        <Modal.Header className="justify-content-start modalHead">
          <Button
            variant="border border-danger text-danger bg-light mx-auto ms-1"
            onClick={handleClose}
            style={{ fontFamily: "Burlingame" }}
          >
            {translationEnabled ? "Chiudi" : "Close"}
          </Button>
          <Modal.Title
            className="mx-auto text-secondary"
            style={{ fontFamily: "SinewsSansPro" }}
          >
            {name}
          </Modal.Title>
          <Button
            variant="border border-primary text-primary bg-light mx-auto me-1"
            onClick={type === "add" ? handleSection : handleEditSection}
            style={{ fontFamily: "Burlingame" }}
          >
            {translationEnabled ? "Salva" : "Save"}
          </Button>
        </Modal.Header>
        <Modal.Body className="backColor border border-secondary p-0">
          <div
            className="text-center"
            style={{ position: "relative", background: "#dfdfdf" }}
          >
            <button
              style={{
                position: "absolute",
                left: "10px",
                top: "20px",
                display: "flex",
                alignItems: "center",
                padding: "5px",
                borderRadius: "10px",
                fontStyle: "italic",
                color: "black",
                border: "2px solid",
              }}
              onClick={() => imageRef.current.click()}
            >
              <span style={{ marginRight: "5px" }}>
                <img src={cameraImg} width="100%" />
              </span>{" "}
              {translationEnabled ? "Cambia foto" : "Change Photo"}
            </button>

            <div className="restaurantLogo m-0">
              <div
                className="PhotoUpload"
                // style={{ background: `url(${image ? image : dummyImage})` }}
              >
                <img
                  src={image ? image : dummyImage}
                  className={image ? "img-fluid restaurantCover" : ""}
                  style={{ width: "100%" }}
                />
              </div>
              <input
                type="file"
                onChange={handleImage}
                style={{ visibility: "hidden" }}
                // accept="image/png, image/gif, image/jpeg"
                ref={imageRef}
              />
            </div>
          </div>

          <div className="container" style={{ padding: "0 10px" }}>
            <form className="row g-3 my-2">
              <div className="col-12">
                <label
                  for="restProduct"
                  className="form-label"
                  style={{ fontFamily: "SinewsSansPro" }}
                >
                  {translationEnabled ? "Nome Sezione" : "SECTION NAME"}
                </label>
                {translationEnabled ? (
                  <input
                    value={inputValue.name}
                    name="name"
                    type="text"
                    className="form-control border border-primary rounded"
                    id="restProduct"
                    placeholder="Inserisci nome sezione"
                    onChange={(e) => onHandleChange(e)}
                    style={{ fontFamily: "SinewsSansPro" }}
                  />
                ) : (
                  <input
                    value={inputValue.name}
                    name="name"
                    type="text"
                    className="form-control border border-primary rounded"
                    id="restProduct"
                    placeholder="Insert Section Name"
                    onChange={(e) => onHandleChange(e)}
                    style={{ fontFamily: "SinewsSansPro" }}
                  />
                )}
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AdminAddSection;
