import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style-sheets/Contacts.css";

import { Button, Modal } from "react-bootstrap";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

function Contacts() {
  const [show, setShow] = useState(false);
  const restData = JSON.parse(localStorage.getItem("restData"));

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [translationEnabled, setTranslationEnabled] = useState(true);

  useEffect(() => {
    const translation = localStorage.getItem("translationEnabled");
    if(translation == "true"){
      setTranslationEnabled(true)
    }else if(translation == "false"){
      setTranslationEnabled(false)
    }else{
      setTranslationEnabled(true)
    }
  }, [])

  return (
    <>
      <Button variant="bg-transparent" onClick={handleShow}>
        <InfoRoundedIcon className="text-primary" />
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header className="justify-content-end bg-light">
          <div className="container">
            <div className="row">
              <Modal.Title
                className=" col-9 mx-auto ms-0"
                style={{ fontFamily: "SinewsSansPro" }}
              >
                 {translationEnabled ? "Contatti" : "Contacts"}
              </Modal.Title>
              <Button
                variant=" col-2 justify-content-end"
                onClick={handleClose}
              >
                <CancelOutlinedIcon className="closeIcon" />
              </Button>
            </div>

            <div
              className="row text-secondary m-0"
              style={{ fontFamily: "SinewsSansPro" }}
            >
              
              {translationEnabled ? "Rimani in contatto con noi" : "Keep in touch with us"}
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid text-secondary">
            {restData?.telephone && <div className="row">
              <img
                src={"/icons/adminstrator-icons/info-restaurant/telephone.svg"}
                className="restIcon2 col"
                alt="address-icon"
              />
              <span
                className="contact col mt-2"
                style={{ fontFamily: "SinewsSansPro" }}
              >
                {restData?.telephone}
              </span>
              <hr />
            </div>}
            {restData?.whatsapp && <div className="row">
              <img
                src="/icons/svg/whatsapp.svg"
                className="infoIcon2"
                alt="whatsapp"
              />
              <span
                className="whatsapp col mt-3"
                style={{ fontFamily: "SinewsSansPro" }}
              >
                {restData?.whatsapp}
              </span>
              <hr />
            </div>
            }
            {restData?.address && <div className="row">
              <img
                src={"/icons/adminstrator-icons/info-restaurant/address.svg"}
                className="restIcon2"
                alt="address-icon"
              />
              <span
                className="address col mt-3"
                style={{ fontFamily: "SinewsSansPro" }}
              >
                {restData?.address}
              </span>
            </div>
            }
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Contacts;
