import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import { Grid, TextField, InputLabel, Typography } from "@mui/material";
import { useStyles } from "./../globalStyles/globalStyles";
import axios from "axios";
import Loader from "./Loader/index";
import { auth_register } from "./../api/index";
import SnackbarAlert from "./snackbar/SnackbarAlert";

const Register = () => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState({
    name: "",
    surname: "",
    email: "",
    password: "",
    restaurantName: "",
    businessName: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if(user?.email){
      window.location = "/"
    }
  },[])

  const onHandleChange = (e) => {
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });
  };

  const handleRegister = () => {
    let { name, surname, email, password, restaurantName, businessName } =
      inputValue;

    inputValue.email = inputValue.email.toLowerCase();

    if (
      name == "" ||
      surname == "" ||
      email == "" ||
      password == "" ||
      restaurantName == ""
    ) {
      setSnackbarProps({
        open: true,
        message: "All fields are required",
        type: "error",
      });
    } else {
      setIsLoading(true);
      axios
        .post(auth_register, inputValue)
        .then((res) => {
         
          setSnackbarProps({
            open: true,
            message: "Registered Successfully",
            type: "success",
          });

          setIsLoading(false);
          setInputValue({
            name: "",
            surname: "",
            email: "",
            password: "",
            restaurantName: "",
            businessName: "",
          });

          localStorage.setItem("user", JSON.stringify(res.data))
          window.location = "/admin";
        })
        .catch((err) => {
          console.log(err, "error");
          setIsLoading(false);
          setSnackbarProps({
            open: true,
            message: err.response.data.message,
            type: "error",
          });
        });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarProps({
      open: false,
      message: "",
      type: "",
    });
  };

  return (
    <div>
      <SnackbarAlert
        handleCloseSnackbar={handleCloseSnackbar}
        snackbarProps={snackbarProps}
      />

      <Loader isLoading={isLoading} />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <Grid item xs={12} sm={4}>
          <div className="header m-3 text-center">
            <img src={"/logo/logo-bump-smart-menu-2.png"} alt="" width="50%" />

            <Typography variant="h4" className={classes.loginHead}>
            Registrazione
            </Typography>
          </div>
          {/* <div className="image">
            <img src={"/icons/adminstrator-icons/mobile-login.svg"} />
          </div> */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="fName" className={classes.globalInputLabel}>
              Nome
              </InputLabel>
              <TextField
                name="name"
                variant="outlined"
                size="small"
                value={inputValue.name}
                onChange={onHandleChange}
                className={classes.globalInput}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="fName" className={classes.globalInputLabel}>
              Cognome
              </InputLabel>
              <TextField
                name="surname"
                variant="outlined"
                size="small"
                value={inputValue.surname}
                onChange={onHandleChange}
                className={classes.globalInput}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="fName" className={classes.globalInputLabel}>
                Email
              </InputLabel>
              <TextField
                name="email"
                variant="outlined"
                size="small"
                value={inputValue.email}
                onChange={onHandleChange}
                className={classes.globalInput}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="fName" className={classes.globalInputLabel}>
                Password
              </InputLabel>
              <TextField
                name="password"
                variant="outlined"
                size="small"
                type="password"
                value={inputValue.password}
                onChange={onHandleChange}
                className={classes.globalInput}
                fullWidth
              />
            </Grid>

            {/* <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="fName" className={classes.globalInputLabel}>
                Business Name
              </InputLabel>
              <TextField
                name="businessName"
                variant="outlined"
                size="small"
                value={inputValue.businessName}
                onChange={onHandleChange}
                className={classes.globalInput}
                fullWidth
              />
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="fName" className={classes.globalInputLabel}>
              Nome Attività
              </InputLabel>
              <TextField
                name="restaurantName"
                variant="outlined"
                size="small"
                value={inputValue.restaurantName}
                onChange={onHandleChange}
                className={classes.globalInput}
                fullWidth
              />
            </Grid>
          </Grid>

          <div
            className="footer"
            style={{ textAlign: "center", margin: "20px 0" }}
          >
            {/* <Link to={"/admin"}> */}
            <button
              type="button"
              onClick={handleRegister}
              className="btn btn-primary"
            >
              Registrati
              {/* Needs API call */}
            </button>
            {/* </Link> */}
          </div>

          <div className="col text-center my-3">
            <Link to="/login" className="link-secondary">
            Già registrato? Accedi
            </Link>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Register;
