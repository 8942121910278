import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style-sheets/AdminAllergen.css";
import { Button, Modal } from "react-bootstrap";

import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { NewspaperTwoTone } from "@mui/icons-material";

const allergens = [
  {
    id: 1,
    name: "celery",
    translatedName: "sedano",
    exclude: false,
  },
  {
    id: 2,
    name: "crustacens",
    translatedName: "crostacei",
    exclude: false,
  },
  {
    id: 3,
    name: "eggs",
    translatedName: "uova",
    exclude: false,
  },
  {
    id: 4,
    name: "fish",
    translatedName: "pesce",
    exclude: false,
  },
  {
    id: 5,
    name: "gluten",
    translatedName: "Glutine",
    exclude: false,
  },
  {
    id: 6,
    name: "lactose",
    translatedName: "lattosio",
    exclude: false,
  },
  {
    id: 7,
    name: "lupin",
    translatedName: "lupini",
    exclude: false,
  },
  {
    id: 8,
    name: "molluscs",
    translatedName: "molluschi",
    exclude: false,
  },
  {
    id: 9,
    name: "mustard",
    translatedName: "senape",
    exclude: false,
  },
  {
    id: 10,
    name: "peanut",
    translatedName: "arachidi",
    exclude: false,
  },
  {
    id: 11,
    name: "sesame",
    translatedName: "sesamo",
    exclude: false,
  },
  {
    id: 12,
    name: "soya",
    translatedName: "soia",
    exclude: false,
  },
  {
    id: 13,
    name: "sulphurdioxide",
    translatedName: "solfiti",
    exclude: false,
  },
  {
    id: 14,
    name: "treenut",
    translatedName: "frutta a guscio",
    exclude: false,
  },
];


function AdminAllergen({
  allergensData,
  setAllergensData,
  dialogData,
  allAlergens,
  setAllAlergens,
}) {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (dialogData) {
      const result = allergensData?.filter(
        (val) => !dialogData?.productAllergens?.some((obj) => val.id == obj.id)
      );

      const isEmpty = Object.keys(dialogData).length === 0;

      if(!isEmpty){
        let newArr = [...dialogData?.productAllergens, ...result];

        for(let i = 0; i<newArr.length; i++){
          let foundAllergen = allergens.find((allergen) => allergen.name === newArr[i].name);
          newArr[i].translatedName = foundAllergen.translatedName;
        }
        
        setAllAlergens(newArr);
      }
     
    } else {
      setAllAlergens(allergensData);
    }
  }, [dialogData]);

  const [translationEnabled, setTranslationEnabled] = useState(true);

  const handleSelectAllergen = (item, i) => {
    let temp = [...allAlergens];
    if (temp[i].active === false) {
      temp[i].active = true;
    } else temp[i].active = false;
    setAllAlergens(temp);
  };

  return (
    <div>
      <div
        variant="btnAllergen"
        onClick={handleShow}
        style={{
          cursor: "pointer",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span className="me-5">
          {" "}
          {translationEnabled ? "Allergeni" : "ALLERGENS"}
        </span>
        <ArrowForwardIosRoundedIcon className="ms-5" />
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <div className="border border-warning text-warning">
          <Modal.Header>
            <Button
              variant="border border-warning text-warning"
              onClick={handleClose}
              style={{ fontFamily: "Burlingame" }}
            >
              {translationEnabled ? "Chiudi" : "Close"}
            </Button>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                paddingRight: "50px"
              }}
            >
            <Modal.Title>
              <div
                className="ModalHeading text-warning"
                style={{ fontFamily: "SinewsSansPro" }}
              >
                {translationEnabled ? "Allergeni" : "ALLERGENS"}
              </div>
            </Modal.Title>
            </div>
          </Modal.Header>
        </div>
        <Modal.Body>
          <div className="text-center">
            <h6 style={{ fontFamily: "SinewsSansPro" }}>
              {translationEnabled
                ? "Seleziona gli allergeni presenti nel prodotto"
                : "Select the Allergens present in product"}
            </h6>
            <div
              className="card m-2 border border-warning"
              style={{ padding: "10px 0" }}
            >
              <div className="allergenContainer">
                {allAlergens.map((allergen, index) => {
                  return (
                    <Button
                      onClick={() => handleSelectAllergen(allergen, index)}
                      style={{
                        backgroundColor: "white",
                        textTransform: "uppercase",
                        fontFamily: "SinewsSansPro",
                      }}
                      className="allergenCol badge border border-success text-success"
                      type="button"
                    >
                      {!allergen.active ? (
                        <div>
                          <img
                            src={`/icons/allergen-icons/allergens-grey/${allergen.name}.svg`}
                            width="25%"
                            style={{ marginRight: "5px" }}
                          />
                        </div>
                      ) : (
                        <div>
                          <img
                            src={`/icons/allergen-icons/allergens-yellow/${allergen.name}.svg`}
                            width="25%"
                            style={{ marginRight: "5px" }}
                          />
                        </div>
                      )}

                      {translationEnabled
                        ? allergen.translatedName
                        : allergen.name}
                        
                    </Button>
                  );
                })}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <Button
            variant="warning text-light"
            onClick={handleClose}
            style={{ fontFamily: "SinewsSansPro" }}
          >
            {translationEnabled ? "Conferma" : "Confirm"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AdminAllergen;
