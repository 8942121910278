import "./App.css";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Register from "./components/Register";
import { AuthRoutes } from "./routes/AuthRoutes";

import Header from "./components/Header";
import Restaurant from "./components/Restaurant";
import Footer from "./components/Footer";
import AdminOrders from "./components/admin/AdminOrders";
import Menu from "./components/MenuView";
import AdminPanel from "./components/AdminPanel";
import AdminPanelLogin from "./components/AdminPanel/login"

// import AdminHeader from "./components/admin/AdminHeader";
// import AdminPhotoUpload from "./components/admin/AdminPhotoUpload";
// import AdminRestaurant from "./components/admin/AdminRestaurant";

import { ThemeProvider } from "@mui/styles";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import AdminView from "./components/AdminView";
import HomePage from "./components/HomePage";
import StartupPage from "./components/StartupPage";

let theme = createTheme();

const App = () => {
  return (
    <div className="App" style={{paddingBottom: "1rem"}}>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/registrati" element={<Register />} />
            <Route exact path="/AdminOrders" element={<AdminOrders />} />

            <Route exact path="/" element={<StartupPage />} />
            <Route exact path="/:id" element={<HomePage />} />
            <Route exact={true} path="/admin" element={<AdminView />} />

            <Route exact={true} path="/adminlogin" element={<AdminPanelLogin />} />
            <Route exact={true} path="/adminpanel" element={<AdminPanel />} />
            
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  );
};

export default App;
