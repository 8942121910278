import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style-sheets/AdminEditInfo.css";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { change_password } from "./../../api/index";
import SnackbarAlert from "./../snackbar/SnackbarAlert";

function ChangePasswordModal({ show, setShow }) {
  const [inputValue, setInputValue] = useState({
    prevPass: "",
    newPass: "",
  });
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });
  const handleClose = () => {
    setShow(false);
  };

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleChange = (e) => {
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });
  };

  const handlePasswordChange = () => {
    const userData = JSON.parse(localStorage.getItem("user"));

    let params = {
      ...inputValue,
      id: userData._id,
    };

    if (inputValue.newPass == "" || inputValue.prevPass == "") {
      handleSnackbarVal(true, "error", "Both fields are required");
    } else {
      axios
        .post(change_password, params)
        .then((res) => {
          handleSnackbarVal(true, "success", res.data.message);
          setInputValue({
            prevPass: "",
            newPass: "",
          });
          setShow(false);
        })
        .catch((err) => {
          handleSnackbarVal(
            true,
            "error",
            "You have entered the wrong password"
          );
        });
    }
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  const [translationEnabled, setTranslationEnabled] = useState(true);

  return (
    <>
      <SnackbarAlert
        handleCloseSnackbar={handleCloseSnackbar}
        snackbarProps={snackbarProps}
      />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="border border-light modalBody"
        centered
      >
        <Modal.Header className="justify-content-start modalHead">
          <Button
            variant="border border-danger text-danger bg-light mx-auto ms-1"
            onClick={handleClose}
            style={{ fontFamily: "Burlingame" }}
          >
            {translationEnabled ? "chiudi" : "Close"}
          </Button>
          <Modal.Title className="mx-auto text-secondary">
            {translationEnabled ? "cambia password" : "Change Password"}
          </Modal.Title>
          <Button
            variant="border border-primary text-primary bg-light mx-auto me-1"
            onClick={handlePasswordChange}
            style={{ fontFamily: "Burlingame" }}
          >
            {translationEnabled ? "salva" : "Save"}
          </Button>
        </Modal.Header>
        <Modal.Body className="backColor border border-secondary">
          <div className="container" style={{ padding: "10px" }}>
            <form className="row g-3">
              <div
                className="border col-12"
                style={{
                  padding: "12px",
                  borderRadius: "12px",
                  background: "#fff",
                }}
              >
                <div className="col-12">
                  <label for="restName" class="form-label">
                    {translationEnabled
                      ? "password precedente"
                      : "Previous Password"}
                  </label>
                  <input
                    type="password"
                    value={inputValue.prevPass}
                    name="prevPass"
                    onChange={handleChange}
                    className="form-control border border-primary"
                    placeholder={
                      translationEnabled
                        ? "password precedente"
                        : "Previous Password"
                    }
                  />
                </div>
                <div className="col-12">
                  <label for="restName" class="form-label">
                    {translationEnabled ? "nuova password" : "New Password"}
                  </label>
                  <input
                    type="password"
                    value={inputValue.newPass}
                    name="newPass"
                    onChange={handleChange}
                    className="form-control border border-primary"
                    placeholder={
                      translationEnabled ? "nuova password" : "New Password"
                    }
                  />
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ChangePasswordModal;
