import React from "react";
import "./style-sheets/Footer.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Cart from "./Cart";
import Contacts from "./Contacts";
import Categories from "./Categories";
import Share from "./Share";

function Footer({ setSelectedProducts, selectedProducts }) {
  const restData = JSON.parse(sessionStorage.getItem("restData"));
  return (
    <div
      className="footer container-fluid bg-light m-0 mt-5"
      style={{ position: "fixed", bottom: 0 }}
    >
      <ul
        className="footerRow nav navbar text-primary"
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <li className="nav-item m-2">
          <Contacts />
        </li>
        <li className="nav-item m-2">
          <Categories />
        </li>
        {restData?.orderFromTable == "true" ? (
          <li className="nav-item m-2">
            <Cart
              setSelectedProducts={setSelectedProducts}
              selectedProducts={selectedProducts}
            />
          </li>
        ) : null}
        <li className="nav-item m-2">
          <Share />
        </li>
      </ul>
    </div>
  );
}

export default Footer;
