// const baseUrl = "http://192.168.0.109:5000/api";
const baseUrl = "https://digitalmenu-bump.herokuapp.com/api";

export const auth_register = `${baseUrl}/auth/register`;
export const auth_login = `${baseUrl}/auth/login`;

export const admin_login = `${baseUrl}/auth/adminlogin`;

// Restaurant API
export const admin_add_restaurant = `${baseUrl}/restaurant/addRestaurant`;
export const admin_edit_restaurant = `${baseUrl}/restaurant`;
export const get_restaurant = `${baseUrl}/restaurant`;
export const admin_add_section = `${baseUrl}/section/addSection`;
export const admin_get_section = `${baseUrl}/section`;
export const admin_edit_section = `${baseUrl}/section`;
export const admin_delete_section = `${baseUrl}/section`;
export const admin_sort_section = `${baseUrl}/section/sort`;
export const admin_sort_product = `${baseUrl}/product/sort`;

export const admin_add_product = `${baseUrl}/product/addProduct`;
export const admin_get_product = `${baseUrl}/product`;
export const admin_edit_product = `${baseUrl}/product`;
export const admin_delete_product = `${baseUrl}/product`;

export const delete_data = `${baseUrl}/restaurant`;

export const change_password = `${baseUrl}/auth/change-pass`;

export const create_order = `${baseUrl}/cart/addCart`;

export const get_restaurant_details = `${baseUrl}/restaurant`;

export const admin_orders = `${baseUrl}/cart`;
export const update_cart_status = `${baseUrl}/cart`;

export const update_user = `${baseUrl}/user`;
export const update_user_notes = `${baseUrl}/user/notes`;
export const update_profile = `${baseUrl}/profile`;
export const update_details = `${baseUrl}/restaurant/editDetail`;
export const get_user = `${baseUrl}/user`;

export const admin_delete_orders = `${baseUrl}/cart/deleteOrders`;

export const admin_get_all_users = `${baseUrl}/user/getallusers`;
export const change_subscription_status = `${baseUrl}/user/changestatus`;