import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style-sheets/Cart.css";
import { Button, Modal } from "react-bootstrap";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import axios from "axios";
import { create_order } from "../api";
import SnackbarAlert from "./snackbar/SnackbarAlert";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { SelectAllTwoTone } from "@mui/icons-material";

function AddToCart({ selectedProductsState, setSelectedProducts }) {
  const selectedProducts = JSON.parse(sessionStorage.getItem("selectedProducts"));
  const restData = JSON.parse(sessionStorage.getItem("restData"));
  const [show, setShow] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const [totalPrice, setTotalPrice] = useState(0);
  const [inputValue, setInputValue] = useState({
    tableNum: "",
    notes: "",
  });
  const [price, setPrice] = React.useState("");

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  useEffect(() => {
    setCartItems(selectedProducts);
  }, [selectedProducts?.length]);

  const [translationEnabled, setTranslationEnabled] = useState(true);

  useEffect(() => {
    const translation = localStorage.getItem("translationEnabled");
    if(translation == "true"){
      setTranslationEnabled(true)
    }else if(translation == "false"){
      setTranslationEnabled(false)
    }else{
      setTranslationEnabled(true)
    }
  }, []);

  useEffect(() => {
    let calculateBill = 0;
    let sum = 0;

    calculateBill = selectedProducts?.map((item) => {
      sum = sum + item.selectedQuantity * item?.price?.price;
    });

    setTotalPrice(parseFloat(sum).toFixed(2));
  }, [cartItems]);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setCartItems(selectedProducts);
  };

  const handleRemoveItem = (item, ind) => {
    let tempArr = [...cartItems];
    let tempArrSelected = [...selectedProducts];

    let removeItems = tempArr.splice(ind, 1);
    let removeItemsSelected = tempArrSelected.splice(ind, 1);
    setSelectedProducts(tempArrSelected);
    setCartItems(tempArr);
    sessionStorage.setItem("selectedProducts", JSON.stringify(tempArr));
  };

  const handleChange = (e) => {
    setInputValue({
      ...inputValue,
      [e.target.name]: e.target.value,
    });
  };

  const handleIncrease = (i) => {
    let tempArr = [...JSON.parse(sessionStorage.getItem("selectedProducts"))];
    
    tempArr[i].selectedQuantity = tempArr[i].selectedQuantity + 1;
    
    setCartItems(tempArr);
    sessionStorage.setItem("selectedProducts", JSON.stringify(tempArr));

  };

  const handleDecrease = (i) => {
    let tempArr = [...cartItems];
    if (tempArr[i].selectedQuantity > 1) {
      tempArr[i].selectedQuantity = tempArr[i].selectedQuantity - 1;
      setCartItems(tempArr);
      sessionStorage.setItem("selectedProducts", JSON.stringify(tempArr));
    } else {
      setSnackbarProps({
        open: true,
        type: "error",
        message: "Quantity cannot be less than 1",
      });
    }
  };

  const handleCreateOrder = () => {
    const restData = JSON.parse(sessionStorage.getItem("restData"));

    let params = {
      ...inputValue,
      restId: restData._id,
      products: cartItems,
      totalBill: parseFloat(totalPrice).toFixed(2),
    };

    if (inputValue.tableNum == "") {
      setSnackbarProps({
        open: true,
        type: "error",
        message: translationEnabled
          ? "Inserisci il numero del tavolo"
          : "Table number is required",
      });
    } else if (cartItems.length < 1) {
      setSnackbarProps({
        open: true,
        type: "error",
        message: translationEnabled ? "Aggiungi i prodotti per effettuare l'ordine" : "Please add products to place order",
      });
    } else {
      axios
        .post(create_order, params)
        .then((res) => {
          setInputValue({
            tableNum: "",
            notes: "",
          });
          setSnackbarProps({
            open: true,
            type: "success",
            message: "Ordine inviato correttamente ",
          });
          setCartItems([]);
          setSelectedProducts([]);
          sessionStorage.removeItem("selectedProducts");
          setShow(false);
        })
        .catch((err) => {
          console.log(err);
          setSnackbarProps({
            open: true,
            type: "error",
            message: "Something went wrong while placing your order",
          });
        });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarProps({
      open: false,
      type: "",
      message: "",
    });
  };

  return (
    <>
      <Button variant="bg-transparent" onClick={handleShow}>
        <img
          width="25px"
          src={"/icons/cart-icons/add-to-cart.png"}
          alt="E404"
          // className="m-1 cartIcon"
        />
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header
          className="bg-light"
          style={{ display: "flex", justifyContent: "start" }}
        >
          <SnackbarAlert
            snackbarProps={snackbarProps}
            handleCloseSnackbar={handleCloseSnackbar}
          />
          <Button
            variant="border border-danger text-danger"
            onClick={handleClose}
            style={{ fontFamily: "Burlingame" }}
          >
            {translationEnabled ? "Chiudi" : "Close"}
          </Button>

          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Modal.Title
              className=" text-secondary"
              style={{ fontFamily: "SinewsSansPro" }}
            >
              {translationEnabled ? "COMPLETA L’ORDINE" : "COMPLETE ORDER "}
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid text-secondary">
            {cartItems !== null &&
              cartItems.length > 0 &&
              cartItems.map((item, ind) => {
                return (
                  <>
                    <div className="row" key={item.category}>
                      <ul className="nav navbar cartProduct">
                        <li
                          className="nav-item"
                          style={{ width: "40%", fontFamily: "SinewsSansPro", listStyleType: "initial" , marginLeft: "10px"}}
                        >
                          <h6 style={{wordBreak: "break-word"}}>{item.name}</h6>
                          {/* <div style={{paddingLeft: "8px"}}>{item.desc}</div> */}

                          <div >
                            {item?.price?.name?.toLowerCase() !== "price" &&
                            item?.price?.name?.toLowerCase() !== "prezzo"
                              ? item?.price?.name
                              : ""}
                          </div>
                        </li>
                        <li className="nav-item">
                          <div
                            className="text-secondary"
                            style={{
                              fontFamily: "SinewsSansPro",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <p style={{ marginBottom: "0px" }}>
                              <span
                                className="rounded"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  fontFamily: "SinewsSansPro",
                                  marginRight: "0.5rem",
                                }}
                              >
                                <button
                                  className="btn p-1 py-0"
                                  onClick={(e) => handleDecrease(ind)}
                                  style={{
                                    border: "1px solid",
                                    borderColor: "rgb(108 124 147)",
                                    borderRadius: "50%",
                                    width: "20px",
                                    marginRight: "3px",
                                  }}
                                >
                                  -
                                </button>
                                {item.selectedQuantity}
                                <button
                                  className="btn p-1 py-0"
                                  onClick={(e) => handleIncrease(ind)}
                                  style={{
                                    border: "1px solid",
                                    borderColor: "rgb(108 124 147)",
                                    borderRadius: "50%",
                                    width: "20px",
                                    marginLeft: "3px",
                                  }}
                                >
                                  +
                                </button>
                              </span>
                            </p>
                          </div>
                        </li>

                        <li
                          className="nav-item"
                          style={{
                            fontFamily: "SinewsSansPro",
                            width: "15%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            className="text-secondary"
                            style={{ fontFamily: "SinewsSansPro" }}
                          >
                            {item?.price?.price}
                            {restData?.currency}
                            <br />
                          </div>
                        </li>
                        <li
                          className="nav-item text-danger"
                          style={{
                            width: "15%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <CloseRoundedIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => handleRemoveItem(item, ind)}
                          />
                        </li>
                      </ul>
                    </div>
                    <hr className="mt-2" />
                  </>
                );
              })}
            {/* ===============implement map above for two rows */}

            <div
              className="row mt-3 justify-content-end"
              style={{ fontFamily: "SinewsSansPro" }}
            >
              {translationEnabled ? "Totale" : "TOTAL"} {totalPrice}
              {restData?.currency}
            </div>

            <div className="row text-center">
              <form>
                {restData && JSON.parse(restData?.orderFromTable) ? (
                  translationEnabled ? (
                    <input
                      type="text"
                      name="tableNum"
                      className="form-control mt-3 mx-auto w-50 border-primary text-center"
                      placeholder="Numero del tavolo"
                      value={inputValue.tableNum}
                      onChange={handleChange}
                      style={{ fontFamily: "SinewsSansPro" }}
                    />
                  ) : (
                    <input
                      type="text"
                      name="tableNum"
                      className="form-control mt-3 mx-auto w-50 border-primary text-center"
                      placeholder="Enter table number"
                      value={inputValue.tableNum}
                      onChange={handleChange}
                      style={{ fontFamily: "SinewsSansPro" }}
                    />
                  )
                ) : null}
                {translationEnabled ? (
                  <input
                    type="text"
                    name="notes"
                    className="form-control mt-3 border-primary text-center"
                    placeholder="Inserisci note"
                    value={inputValue.notes}
                    onChange={handleChange}
                    style={{ fontFamily: "SinewsSansPro" }}
                  />
                ) : (
                  <input
                    type="text"
                    name="notes"
                    className="form-control mt-3 border-primary text-center"
                    placeholder="Enter notes"
                    value={inputValue.notes}
                    onChange={handleChange}
                    style={{ fontFamily: "SinewsSansPro" }}
                  />
                )}
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <div className="container">
            <div className="row mx-auto m-2 text-center w-50">
              <Button
                className="btn btn-success orderBtn "
                onClick={handleCreateOrder}
                style={{ fontFamily: "SinewsSansPro" }}
              >
                {translationEnabled ? "Invia ordine" : "SEND ORDER"}
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddToCart;
