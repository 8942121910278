import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style-sheets/AdminEditWifi.css";

import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import Loader from "../Loader";
import { admin_edit_restaurant } from "./../../api/index";

function AdminEditWifi({
  wifiInputValue,
  setWifiInputValue,
  allRestaurants,
  handleSnackbarVal,
  getRestaurants,
}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isLoading, setIsLoading] = useState(false);

  const [translationEnabled, setTranslationEnabled] = useState(true);

  const handleChange = (e) => {
    setWifiInputValue({ ...wifiInputValue, [e.target.name]: e.target.value });
  };

  const handleUpdateWifi = () => {
    let params = {
      wifi_name: wifiInputValue.wifi_name,
      wifi_password: wifiInputValue.wifi_password,
      isActive: "Active",
    };

    if (wifiInputValue.wifi_name == "" || wifiInputValue.wifi_paswword == "") {
      handleSnackbarVal(true, "error", "Please fill both fieds");
    } else {
      axios
        .put(`${admin_edit_restaurant}/${allRestaurants[0]?._id}`, params)
        .then((res) => {
          setIsLoading(false);
          handleSnackbarVal(true, "success", "Wifi Updated Successfully");
          getRestaurants();
          setShow(false);
          wifiInputValue({
            wifi_name: "",
            wifi_paswword: "",
          });
        })

        .catch((err) => {
          console.log(err, "error");
          setIsLoading(false);
          
        });
    }
  };

  return (
    <>
      <Loader isloading={isLoading} />
      <Button
        variant="bg-transparent"
        onClick={handleShow}
        className="border border-dark borderRound modalHead"
        style={{
          marginRight: "5px",
          padding: "0 20px",
          width: "150px"
        }}
      >
        <img
          src="/icons/adminstrator-icons/edit.svg"
          className="my-1 me-2 editWifiImg"
          alt=""
        />
        <span style={{ fontFamily: "SinewsSansPro", width: "150px" }}>
        {translationEnabled ? "Modifica Sezione Wi-Fi" : "Edit wifi"}
        </span>
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="border border-light modalBody"
        centered
      >
        <Modal.Header className="justify-content-start modalHead">
          <Button
            variant="border border-danger text-danger bg-light mx-auto ms-1"
            onClick={handleClose}
            style={{ fontFamily: "Burlingame" }}
          >
            {translationEnabled ? "Chiudi" : "Close"}
          </Button>
          <Modal.Title className="mx-auto text-secondary">
            WIFI INFO
          </Modal.Title>
          <Button
            variant="border border-primary text-primary bg-light mx-auto me-1"
            onClick={handleUpdateWifi}
            style={{ fontFamily: "Burlingame" }}
          >
             {translationEnabled ? "Salva" : "Save"}
          </Button>
        </Modal.Header>
        <Modal.Body className="backColor border border-secondary">
            <form>
            
              <div style={{display: "flex", justifyContent: "center", flexDirection: "column"}} >

                <div style={{display: "flex", justifyContent: "center"}}>
                  <span className="col-4">{translationEnabled ? "Linea" : "LINEA WIFI:"}</span>
                  <input
                    type="text"
                    className="form-control border border-primary rounded"
                    id="wifiName"
                    placeholder="Inserisci linea Wi-Fi"
                    value={wifiInputValue.wifi_name}
                    onChange={handleChange}
                    name="wifi_name"
                    style={{width: "70%"}}
                  />
                </div>

                <div  style={{display: "flex", justifyContent: "center", marginTop: "10px"}}>
                  <span className=" col-4">{translationEnabled ? "Password" : "PASSWORD:"}</span>
                  <input
                    type="text"
                    className="form-control border border-primary rounded"
                    id="wifiPassword"
                    placeholder="Inserisci password"
                    value={wifiInputValue.wifi_password}
                    onChange={handleChange}
                    name="wifi_password"
                    style={{width: "70%"}}
                  />
                </div>
              </div>
            </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AdminEditWifi;
