import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

function AdminPrice({ onChange, name, index, onChangeDelete, value }) {
  return (
    <span className="row my-2">
      <input
        type="text"
        className="form-control border border-primary rounded w-50 mx-3"
        id="productPrice"
        placeholder="Price"
        value={value.name}
      />
      <input
        name={name}
        value={value.price}
        onChange={(e) => onChange(e, index)}
        type="number"
        className="form-control border border-primary rounded me-1 w-25"
        placeholder="5.00"
      />
      €
      <img
        onClick={onChangeDelete}
        src="/icons/adminstrator-icons/delete.svg"
        className="menuImgDel"
        alt=""
      />
    </span>
  );
}

export default AdminPrice;
