import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style-sheets/AdminEditInfo.css";
import PersonIcon from "@mui/icons-material/Person";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";

import { Link } from "react-router-dom";

import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import LinkIcon from "@mui/icons-material/Link";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { Button, Modal } from "react-bootstrap";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import TranslateIcon from "@mui/icons-material/Translate";
import PasswordIcon from "@mui/icons-material/Password";
import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";
import HelpIcon from "@mui/icons-material/Help";
import {
  ChromePicker as ColorPickerSection,
  ChromePicker as ColorPickerFont,
} from "react-color";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import AdminProfileModal from "./AdminProfileModal";
import ChangePasswordModal from "./ChangePasswordModal";
import { update_details, get_restaurant } from "../../api";
import axios from "axios";
import CustomLinkModal from "./CustomLinkModal";
import SnackbarAlert from "./../snackbar/SnackbarAlert";
import QRCode from "qrcode.react";
import { useStyles } from "./../../globalStyles/globalStyles";
import Assitance from "./Assistance";

function SettingsModal({ show, setShow }) {
  const classes = useStyles();
  const [showPicker, setShowPicker] = useState(false);
  const [themeColor, setThemeColor] = useState("#fff");
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showCustomLinkModal, setShowCustomLinkModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showAssitance, setShowAssitance] = useState(false);
  const [orderFromTable, setOrderFromTable] = useState(false);
  const [currency, setCurrency] = useState("€");
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const restData = JSON.parse(localStorage.getItem("restData"));
  const userData = JSON.parse(localStorage.getItem("user"));

  const [showFontPicker, setShowFontPicker] = useState(false);
  const [fontColor, setFontColor] = useState("#ffff");
  const [translationEnabled, setTranslationEnabled] = useState(true);

  const handleClose = () => {
    setShow(false);
  };

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    let data;
    axios
      .get(`${get_restaurant}/?id=${userData._id}`)
      .then((res) => {
        if (res.data.data.length > 0) {
          // localStorage.setItem("restData", JSON.stringify(res.data.data[0]));
          data = res.data.data[0];
          console.log(data);
          if (data) {
            setCurrency(data?.currency);
            setOrderFromTable(JSON.parse(data?.orderFromTable));
            setThemeColor(data?.themeColor);
            setFontColor(data?.fontColor);
          }
        }
      })
      .catch((err) => {
        console.log(err, "error");
        setSnackbarProps({
          open: true,
          message: err?.response?.data?.message,
          type: "error",
        });
      });
  }, []);

  const handleClickPicker = () => {
    setShowPicker(!showPicker);
  };
  const handleClosePicker = () => {
    setShowPicker(false);
  };

  const handleChangePicker = (color, event) => {
    setThemeColor(color.hex);
  };

  const handleChangeChecked = (event) => {
    console.log(event.target.checked);
    setOrderFromTable(event.target.checked);
  };

  const handleEditDetail = () => {
    let params = {
      currency,
      orderFromTable,
    };
    if (currency == "") {
      handleSnackbarVal(true, "error", "Inserisci la valuta");
    } else {
      axios
        .put(`${update_details}/${restData._id}`, params)
        .then((res) => {
          handleSnackbarVal(
            true,
            "success",
            "Restaurant details updated successfully"
          );
          localStorage.setItem("restData", JSON.stringify(res.data.data));
          setCurrency("");
          setShow(false);
          window.location.reload();
        })
        .catch((err) => {
          console.log(err, "error");
          setSnackbarProps({
            open: true,
            message: err?.response?.data?.message,
            type: "error",
          });
        });
    }
  };

  const handleUpdateTheme = () => {
    debugger;
    if (themeColor == "") {
      handleSnackbarVal(true, "error", "Seleziona il colore");
    } else {
      axios
        .put(`${update_details}/${restData._id}`, { themeColor })
        .then((res) => {
          handleSnackbarVal(true, "success", "Tema aggiornato correttamente");
          localStorage.setItem("restData", JSON.stringify(res.data.data));
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        })
        .catch((err) => {
          console.log(err, "error");
          setSnackbarProps({
            open: true,
            message: err?.response?.data?.message,
            type: "error",
          });
        });
    }
  };

  const handleFontUpdate = () => {
    debugger;
    if (fontColor == "") {
      handleSnackbarVal(true, "error", "Seleziona il colore");
    } else {
      axios
        .put(`${update_details}/${restData._id}`, { fontColor })
        .then((res) => {
          handleSnackbarVal(true, "success", "Tema aggiornato correttamente");
          localStorage.setItem("restData", JSON.stringify(res.data.data));
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        })
        .catch((err) => {
          console.log(err, "error");
          setSnackbarProps({
            open: true,
            message: err?.response?.data?.message,
            type: "error",
          });
        });
    }
  };

  const popover = {
    position: "absolute",
    zIndex: "999",
    marginTop: "30px",
    top: "80px",
    textAlign: "-webkit-center",
  };
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  const downloadQRCode = () => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `website-link.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <>
      <SnackbarAlert
        handleCloseSnackbar={handleCloseSnackbar}
        snackbarProps={snackbarProps}
      />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="border border-light modalBody"
        centered
      >
        <Modal.Header className="justify-content-start modalHead">
          <Button
            variant="border border-danger text-danger bg-light mx-auto ms-1"
            onClick={handleClose}
            style={{ fontFamily: "Burlingame" }}
          >
            {translationEnabled ? "Chiudi" : "Close"}
          </Button>
          <Modal.Title className="mx-auto text-secondary">
            {translationEnabled ? "impostazioni" : "Settings"}
          </Modal.Title>
          <Button
            style={{ cursor: "pointer", fontFamily: "Burlingame" }}
            variant="border border-primary text-primary bg-light mx-auto me-1"
            onClick={handleEditDetail}
          >
            {translationEnabled ? "Salva" : "Save"}
          </Button>
        </Modal.Header>
        <Modal.Body className="backColor border border-secondary">
          <div className="container">
            <form className="row g-3">
              <div
                className="border col-12"
                style={{ borderRadius: "12px", background: "#fff" }}
              >
                <ListItem
                  style={{ cursor: "pointer" }}
                  divider
                  onClick={() => setShowProfileModal(true)}
                  className={classes.listItemStyle}
                >
                  <ListItemIcon style={{ minWidth: "40px" }}>
                    <PersonIcon />
                  </ListItemIcon>

                  {translationEnabled ? (
                    <ListItemText primary="Informazioni profilo" />
                  ) : (
                    <ListItemText primary="Profile Information" />
                  )}
                </ListItem>
                <ListItem
                  divider
                  className={classes.listItemStyle}
                  secondaryAction={
                    <ListItemIcon edge="end">
                      <button
                        type="button"
                        onClick={handleClickPicker}
                        style={{
                          width: "22px",
                          height: "22px",
                          borderRadius: "5px",
                          marginRight: "10px",
                          background: themeColor,
                        }}
                      ></button>
                      <button
                        type="button"
                        style={{
                          borderRadius: "8px",
                          background: "#fff",
                          border: "none",

                          fontFamily: "Burlingame",
                        }}
                        onClick={handleUpdateTheme}
                      >
                           {translationEnabled ? "Salva" : "Save"}
                      </button>
                    </ListItemIcon>
                  }
                >
                  <ListItemIcon style={{ minWidth: "40px" }}>
                    <ColorLensIcon />
                  </ListItemIcon>

                  {translationEnabled ? (
                    <ListItemText primary="Cambia il colore delle sezioni" />
                  ) : (
                    <ListItemText primary="Change Section's Color" />
                  )}
                </ListItem>

                <ListItem
                  divider
                  className={classes.listItemStyle}
                  secondaryAction={
                    <ListItemIcon edge="end">
                      <button
                        type="button"
                        onClick={()=> setShowFontPicker(!showFontPicker)}
                        style={{
                          width: "22px",
                          height: "22px",
                          borderRadius: "5px",
                          marginRight: "10px",
                          background: fontColor,
                        }}
                      ></button>
                      <button
                        type="button"
                        style={{
                          borderRadius: "8px",
                          background: "#fff",
                          border: "none",

                          fontFamily: "Burlingame",
                        }}
                        onClick={handleFontUpdate}
                      >
                        {translationEnabled ? "Salva" : "Save"}
                      </button>
                    </ListItemIcon>
                  }
                >
                  <ListItemIcon style={{ minWidth: "40px" }}>
                    <ColorLensIcon />
                  </ListItemIcon>

                  {translationEnabled ? (
                    <ListItemText primary="Cambia il colore del Font" />
                  ) : (
                    <ListItemText primary="Change Font's Color" />
                  )}
                </ListItem>

                <ListItem
                  divider
                  className={classes.listItemStyle}
                  secondaryAction={
                    <ListItemIcon edge="end">
                      <input
                        type="text"
                        value={currency}
                        onChange={(e) => setCurrency(e.target.value)}
                        className="form-control border border-primary"
                        placeholder="$"
                        style={{
                          width: "20%",
                          marginLeft: "auto",
                          textAlign: "center",
                        }}
                      />
                    </ListItemIcon>
                  }
                >
                  <ListItemIcon style={{ minWidth: "40px" }}>
                    <CurrencyExchangeIcon />
                  </ListItemIcon>

                  {translationEnabled ? (
                    <ListItemText primary="Valuta" />
                  ) : (
                    <ListItemText primary="Currency" />
                  )}
                </ListItem>
                <ListItem
                  divider
                  style={{ cursor: "pointer" }}
                  onClick={downloadQRCode}
                  className={classes.listItemStyle}
                >
                  <ListItemIcon style={{ minWidth: "40px" }}>
                    <QrCodeScannerIcon />
                  </ListItemIcon>

                  {translationEnabled ? (
                    <ListItemText primary="Il mio QR-Code" />
                  ) : (
                    <ListItemText primary="My QR-Code" />
                  )}
                </ListItem>
                <div
                  style={{
                    background: "white",
                    padding: "16px",
                    display: "none",
                  }}
                >
                  <QRCode
                    id="qr-gen"
                    value={`${window.location.host}/${restData?.restUrl}`}
                  />
                  ,
                </div>
                <ListItem
                  divider
                  className={classes.listItemStyle}
                  secondaryAction={
                    <ListItemIcon edge="end">
                      <Switch
                        checked={orderFromTable}
                        value={orderFromTable}
                        size="small"
                        onChange={handleChangeChecked}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </ListItemIcon>
                  }
                >
                  <ListItemIcon style={{ minWidth: "40px" }}>
                    <img
                      width="25px"
                      src={"/icons/cart-icons/add-to-cart.png"}
                      alt="E404"
                    />
                  </ListItemIcon>

                  {translationEnabled ? (
                    <ListItemText primary="Ordini dal tavolo" />
                  ) : (
                    <ListItemText primary="Order from the Table" />
                  )}
                </ListItem>
                <ListItem
                  divider
                  style={{ cursor: "pointer" }}
                  className={classes.listItemStyle}
                  onClick={() => setShowCustomLinkModal(true)}
                >
                  <ListItemIcon style={{ minWidth: "40px" }}>
                    <LinkIcon />
                  </ListItemIcon>

                  {translationEnabled ? (
                    <ListItemText primary="Link personalizzato" />
                  ) : (
                    <ListItemText primary="Custom link" />
                  )}
                </ListItem>
                <ListItem
                  divider
                  style={{ cursor: "pointer" }}
                  className={classes.listItemStyle}
                  onClick={() => setShowPasswordModal(true)}
                >
                  <ListItemIcon style={{ minWidth: "40px" }}>
                    <PasswordIcon />
                  </ListItemIcon>

                  {translationEnabled ? (
                    <ListItemText primary="Cambia password" />
                  ) : (
                    <ListItemText primary="Change Password" />
                  )}
                </ListItem>
                <ListItem
                  divider
                  style={{ cursor: "pointer" }}
                  className={classes.listItemStyle}
                  onClick={() => setShowAssitance(true)}
                >
                  <ListItemIcon style={{ minWidth: "40px" }}>
                    <HelpIcon />
                  </ListItemIcon>
                  <ListItemText primary="Assistance" />
                </ListItem>
                <ListItem divider className={classes.listItemStyle}>
                  <ListItemIcon style={{ minWidth: "40px" }}>
                    <LogoutRoundedIcon />
                  </ListItemIcon>
                  <ListItemText>
                    <Link
                      to={"/login"}
                      onClick={() => localStorage.clear()}
                      style={{ textDecoration: "none" }}
                      className="text-dark"
                    >
                      Logout
                    </Link>
                  </ListItemText>
                </ListItem>
              </div>
              {showPicker ? (
                <div style={popover}>
                  <div style={cover} onClick={handleClosePicker} />
                  <ColorPickerSection
                    color={themeColor}
                    onChange={handleChangePicker}
                  />
                </div>
              ) : null}
              {showFontPicker ? (
                <div style={popover}>
                  <div style={cover} onClick={()=>setShowFontPicker(false)} />
                  <ColorPickerFont
                    color={fontColor}
                    onChange={(color, event) => {
                      setFontColor(color.hex);
                    }}
                  />
                </div>
              ) : null}
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <AdminProfileModal
        show={showProfileModal}
        setShow={setShowProfileModal}
      />
      <CustomLinkModal
        show={showCustomLinkModal}
        setShow={setShowCustomLinkModal}
      />
      <ChangePasswordModal
        show={showPasswordModal}
        setShow={setShowPasswordModal}
      />
      <Assitance show={showAssitance} setShow={setShowAssitance} />
    </>
  );
}

export default SettingsModal;
