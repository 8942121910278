import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style-sheets/AdminEditInfo.css";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { update_user } from "../../api";
import SnackbarAlert from "../snackbar/SnackbarAlert";
import Loader from "./../Loader/index";

const restData = JSON.parse(localStorage.getItem("restData"));
const userData = JSON.parse(localStorage.getItem("user"));

function AdminProfileModal({ show, setShow }) {
  const [inputValue, setInputValue] = useState({
    name: "",
    email: "",
    subscription: "",
    restName: "",
  });
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const [restUrl, setRestUrl] = useState("");

  const restData = JSON.parse(localStorage.getItem("restData"));
  const userData = JSON.parse(localStorage.getItem("user"));
  
  const [translationEnabled, setTranslationEnabled] = useState(true);

  useEffect(() => {
    setRestUrl(restData?.restUrl);
    setInputValue({
      name: userData?.name,
      email: userData?.email,
      restName: userData?.restaurant_name,
      subscription_status: userData?.subscription_status
    });
  }, []);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const handleChange = (e) => {
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });
  };

  const handleUpdateProfile = () => {
    let userParams = {
      name: inputValue.name,
      email: inputValue.email,
      restaurant_name: inputValue.restName,
    };

    if (inputValue.name == "" || inputValue.email == "" || inputValue.restName == "") {
      setSnackbarProps({
        open: true,
        type: "success",
        message: "Name & Email & Business Name cannot be empty",
      });
    } else {
      setIsLoading(true);
      axios
        .put(`${update_user}/${userData._id}`, userParams)
        .then((res) => {
          setIsLoading(false);
          localStorage.setItem("user", JSON.stringify(res.data.data));
          setSnackbarProps({
            open: true,
            type: "success",
            message: "Profile updated successfully",
          });
          setShow(false);
          window.location.reload();
        })
        .catch((err) => {
          setIsLoading(false);
          setSnackbarProps({
            open: true,
            type: "error",
            message: "Email & Restaurant Name should be unique. Please use another one.",
          });
          console.log(err, "Errr");
        });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarProps({
      open: false,
      type: "",
      message: "",
    });
  };

  return (
    <>
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Loader isLoading={isLoading} />

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="border border-light modalBody"
        centered
      >
        <Modal.Header className="justify-content-start modalHead">
          <Button
            variant="border border-danger text-danger bg-light mx-auto ms-1"
            onClick={handleClose}
            style={{ fontFamily: "Burlingame" }}
          >
            {translationEnabled ? "chiudi" : "Close"}
          </Button>
          <Modal.Title className="mx-auto text-secondary" style={{paddingLeft: "30px"}}>
            {translationEnabled ? "Informazioni Profilo" : "Profile Information"}
          </Modal.Title>
          <Button
            onClick={handleUpdateProfile}
            variant="border border-primary text-primary bg-light mx-auto me-1"
            style={{ fontFamily: "Burlingame" }}
          >
            {translationEnabled ? "salva" : "Save"}
          </Button>
        </Modal.Header>
        <Modal.Body className="backColor border border-secondary">
          <div className="container" style={{ padding: "10px" }}>
            <form className="row g-3">
              <div
                className="border col-12"
                style={{
                  padding: "12px",
                  borderRadius: "12px",
                  background: "#fff",
                }}
              >
                <div className="col-12" style={{marginTop: "10px"}}>
                  <label
                    for="restName"
                    class="form-label"
                    style={{ textTransform: "uppercase" }}
                  >
                    

                    {translationEnabled ? "nome & cognome" : "Name & Surname"}
                  </label>
                  <input
                    type="text"
                    value={inputValue.name}
                    name="name"
                    onChange={handleChange}
                    className="form-control border border-primary"
                    placeholder="Name"
                  />
                </div>
                <div className="col-12" style={{marginTop: "10px"}}>
                  <label
                    for="restName"
                    class="form-label"
                    style={{ textTransform: "uppercase" }}
                  >
                    Email
                  </label>
                  <input
                    type="text"
                    value={inputValue.email}
                    name="email"
                    onChange={handleChange}
                    className="form-control border border-primary"
                    placeholder="Email"
                  />
                </div>
                <div className="col-12" style={{marginTop: "10px"}}>
                  <label
                    for="restName"
                    class="form-label"
                    style={{ textTransform: "uppercase" }}
                  >
                    {translationEnabled ? "ragione sociale" : "Business Name"}
                  </label>
                  <input
                    type="text"
                    value={inputValue.restName}
                    name="restName"
                    onChange={handleChange}
                    className="form-control border border-primary"
                    placeholder="Restaurant Name"
                  />
                </div>
                <div className="col-12" style={{marginTop: "10px"}}>
                  <label for="restName" class="form-label"    style={{ textTransform: "uppercase" }}>
                    
                    {translationEnabled ? "stato abbonamento" : "Subscription Status"}
                  </label>
                  <input
                    type="text"
                    value={inputValue.subscription_status ? "Active" : "Disabled"}
                    name="subscription"
                    disabled
                    onChange={handleChange}
                    className="form-control border border-primary"
                    placeholder="Active from gg/mm/aaaa"
                  />
                </div>
                {/* <div className="col-12">
                  <label for="restName" class="form-label">
                    Last Payment
                  </label>
                  <input
                    type="text"
                    value={inputValue.lastPayment}
                    name="lastPayment"
                    disabled
                    onChange={handleChange}
                    className="form-control border border-primary"
                    placeholder="22/09/2021"
                  />
                </div>
                <div className="col-12">
                  <label for="restName" class="form-label">
                    Next Renewal
                  </label>
                  <input
                    type="text"
                    value={inputValue.nextRenewal}
                    name="nextRenewal"
                    disabled
                    onChange={handleChange}
                    className="form-control border border-primary"
                    placeholder="22/12/2022"
                  />
                </div>
                <div className="col-12">
                  <label for="restName" class="form-label">
                    Payment Method
                  </label>
                  <input
                    type="text"
                    value={inputValue.paymentMethod}
                    name="paymentMethod"
                    disabled
                    onChange={handleChange}
                    className="form-control border border-primary"
                    placeholder="Credit Card Ending in 5514"
                  />
                </div> */}
                {/* <label for="restName" class="form-label">
                  Restaurant Url
                </label>
                <div style={{ display: "flex" }}>
                  <div className="col-8">
                    <input
                      type="text"
                      value={restUrl}
                      name="restUrl"
                      onChange={(e) => setRestUrl(e.target.value)}
                      className="form-control border border-primary"
                      placeholder="Restaurant URL"
                    />
                  </div>
                  <div className="col-4" style={{ marginLeft: 10 }}>
                    <Button
                      onClick={changeRestName}
                      variant="border border-primary text-primary bg-light mx-auto me-1"
                    >
                      Save
                    </Button>
                  </div>
                </div> */}
                {/* <div
                  className="col-12"
                  style={{ margin: "10px 0", textAlign: "right" }}
                >
                  <Button variant="border border-primary text-primary bg-light mx-auto me-1">
                    Save
                  </Button>
                </div> */}
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AdminProfileModal;
