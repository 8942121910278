import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style-sheets/AdminRestaurant.css";

import AdminEditInfo from "./AdminEditInfo";
import AdminEditWifi from "./AdminEditWifi";
import AdminMenuView from "./AdminMenuView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { Button } from "react-bootstrap";
import Loader from "./../Loader/index";
import axios from "axios";
import SnackbarAlert from "./../snackbar/SnackbarAlert";
import {
  admin_add_restaurant,
  admin_edit_restaurant,
  get_restaurant,
  get_user,
} from "./../../api/index";
import Highlighter from "../highlighter/Highlighter";
import { useStyles } from "./../../globalStyles/globalStyles";
import { Container } from "@mui/material";
import AdminPhotoUpload from "./AdminPhotoUpload";

function Restaurant() {
  const classes = useStyles();
  const [disable, setDisable] = useState(false);
  const [show, setShow] = useState(false);
  const userData = JSON.parse(localStorage.getItem("user"));
  const restData = JSON.parse(localStorage.getItem("restData"));
  const [inputValue, setInputValue] = useState({
    restaurantName: userData?.restaurant_name,
    biography: "",
    streetAddress: "",
    telephone: "",
    instaLink: "",
    fbLink: "",
    whatsappNum: "",
    tripadivsorLink: "",
  });
  const [wifiInputValue, setWifiInputValue] = useState({
    wifi_name: "",
    wifi_password: "",
  });
  const [allRestaurants, setAllRestaurants] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState("add");
  const [editItem, setEditItem] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [resImage, setResImage] = useState(null);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const [translationEnabled, setTranslationEnabled] = useState(true);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const getRestaurants = () => {
    setIsLoading(true);
    axios
      .get(`${get_restaurant}/?id=${userData._id}`)
      .then((res) => {
        setIsLoading(false);
        setAllRestaurants(res.data.data);
        if (res.data.data.length > 0) {
          localStorage.setItem("restData", JSON.stringify(res.data.data[0]));
        }
        setDisable(JSON.parse(res?.data?.data[0].isWifiAvailable));
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err, "error");
        setSnackbarProps({
          open: true,
          message: err?.response?.data?.message,
          type: "error",
        });
      });
  };

  useEffect(() => {
    getRestaurants();
  }, []);

  useEffect(() => {
    setResImage(allRestaurants[0]?.imageUrl);
  }, [allRestaurants]);

  const handleDisable = () => {
    setDisable(!disable);
    let params = {
      isWifiAvailable: `${!disable}`,
    };
    axios
      .put(`${admin_edit_restaurant}/${allRestaurants[0]?._id}`, params)
      .then((res) => {
        setIsLoading(false);
        handleSnackbarVal(
          true,
          "success",
          "Sezione Wi-Fi aggiornata con successo"
        );
        getRestaurants();
        setShow(false);
      })

      .catch((err) => {
        console.log(err, "error");
        setIsLoading(false);
        setSnackbarProps({
          open: true,
          message: err?.response?.data?.message,
          type: "error",
        });
      });
  };

  const handleAddEditRestaurant = () => {
    let {
      restaurantName,
      biography,
      streetAddress,
      telephone,
      instaLink,
      fbLink,
      whatsappNum,
      tripadivsorLink,
    } = inputValue;

    let { wifi_name, wifi_password } = wifiInputValue;

    let params = {
      name: restaurantName,
      biography: biography,
      address: streetAddress,
      telephone,
      instagram: instaLink,
      facebook: fbLink,
      whatsapp: whatsappNum,
      tripadvisor: tripadivsorLink,
      adminId: userData._id,
      wifi_name,
      wifi_password,
      isActive: "Active",
    };

    if(!restaurantName.length){
      handleSnackbarVal(true, "error", "Restaurant cannot be empty.");
      return;
    }

    setIsLoading(true);
    if (type == "add") {
      axios
        .post(admin_add_restaurant, params)
        .then((res) => {
          setIsLoading(false);
          getRestaurants();
          handleSnackbarVal(true, "success", "Restaurant created successfully");
          setInputValue({
            restaurantName: "",
            biography: "",
            streetAddress: "",
            telephone: "",
            instaLink: "",
            fbLink: "",
            whatsappNum: "",
            tripadivsorLink: "",
          });
          setShow(false);
        })
        .catch((err) => {
          console.log(err, "error");
          handleSnackbarVal(true, "error", err?.response?.data?.message,);
          setIsLoading(false);
        });
    } else {
      axios
        .put(`${admin_edit_restaurant}/${editItem}`, params)
        .then((res) => {
          setIsLoading(false);
          handleSnackbarVal(
            true,
            "success",
            "Restaurant info updated successfully"
          );
          getRestaurants();
          const tempUser = JSON.parse(localStorage.getItem('user'));
          axios.get(`${get_user}/${tempUser?.email}`)
          .then((res) => {
            localStorage.setItem('user', JSON.stringify(res.data.data));
            window.location.reload();  
          });

          setShow(false);
        })
        .catch((err) => {
          console.log(err, "error");
          setIsLoading(false);
          setSnackbarProps({
            open: true,
            message: err?.response?.data?.message,
            type: "error",
          });
        });
    }
    // }
    
  };

  const handleClickEdit = (item) => {
    setShow(true);
    setEditItem(item._id);
    setType("edit");
    setInputValue({
      restaurantName: item.name,
      biography: item.biography,
      streetAddress: item.address,
      telephone: item.telephone,
      instaLink: item.instagram,
      fbLink: item.facebook,
      whatsappNum: item.whatsapp,
      tripadivsorLink: item.tripadvisor,
    });
    setWifiInputValue({
      wifi_name: item.wifi_name,
      wifi_password: item.wifi_password,
      isActive: "Active",
    });
  };

  // const handleDelete = (item) => {
  //   setIsLoading(false);
  //   axios
  //     .delete(`${admin_edit_restaurant}/${item._id}`)
  //     .then((res) => {
  //       setIsLoading(false);
  //       getRestaurants();
  //       handleSnackbarVal(true, "success", res.data.message);
  //     })
  //     .catch((err) => {
  //       console.log(err, "error");
  //       setIsLoading(false);
  //       setSnackbarProps({
  //         open: true,
  //         message: err?.response?.data?.message,
  //         type: "error",
  //       });
  //     });
  // };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  return (
    <div className="restaurant m-0">
      <SnackbarAlert
        handleCloseSnackbar={handleCloseSnackbar}
        snackbarProps={snackbarProps}
      />
      <Loader isLoading={isLoading} />

      <section
        style={{
          width: "100%",
          color: "white",
          backgroundColor: "white",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <AdminPhotoUpload
          image={resImage}
          setImage={setResImage}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          handleSnackbarVal={handleSnackbarVal}
          getRestaurants={getRestaurants}
        />
      </section>

      <Container maxWidth="md">
        <div
          className="row m-4 justify-content-center"
          style={{ position: "relative" }}
        >
          <AdminEditInfo
            type={type}
            show={show}
            setType={setType}
            setShow={setShow}
            inputValue={inputValue}
            setInputValue={setInputValue}
            handleAddEditRestaurant={handleAddEditRestaurant}
            allRestaurants={allRestaurants}
          />
          {allRestaurants.length > 0 &&
            allRestaurants.map((item) => {
              return (
                <div className="text-center card card-body">
                  <h4
                    style={{
                      fontFamily: "SinewsSansPro",
                      textTransform: "capitalize",
                    }}
                  >
                    {item.name}
                  </h4>
                  {/* Restuarant Name Here*/}

                  {/* Restaurant Biography */}
                  <p style={{ fontFamily: "SinewsSansPro" }}>
                    {item.biography}
                  </p>
                  <div className="restaurantCard  mx-auto text-center">
                    <p className="card-text">
                      <img
                        src={
                          "/icons/adminstrator-icons/info-restaurant/address.svg"
                        }
                        className="restIcon"
                        alt="address-icon"
                      />
                      {item.address}
                    </p>
                    <p
                      className="card-text"
                      style={{ fontFamily: "SinewsSansPro" }}
                    >
                      <img
                        src={
                          "/icons/adminstrator-icons/info-restaurant/telephone.svg"
                        }
                        className="restIcon"
                        alt="phone-icon"
                      />{" "}
                      {item.telephone}
                    </p>
                    <p
                      className="card-text"
                      style={{ fontFamily: "SinewsSansPro" }}
                    >
                        <img
                          src={
                            "/icons/adminstrator-icons/info-restaurant/instagram-logo.png"
                          }
                          className="infoIconAdmin"
                          alt="Insta"
                        />
                        <img
                          src={
                            "/icons/adminstrator-icons/info-restaurant/facebook-logo.png"
                          }
                          className="infoIconAdmin"
                          alt="fb"
                        />
                        <img
                          src={
                            "/icons/adminstrator-icons/info-restaurant/whatsapp-logo.png"
                          }
                          className="infoIconAdmin"
                          alt="whatsapp"
                        />
                        <img
                          src={
                            "/icons/adminstrator-icons/info-restaurant/tripadvisor-logo.png"
                          }
                          className="infoIconAdmin"
                          alt="tripadvisor"
                        />
                    </p>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "-22px",
                      width: "100%",
                      left: 0,
                      right: 0,
                    }}
                  >
                    <Button
                      className="row badge badge-pill badge-light text-dark border border-dark m-2 modalHead"
                      variant="bg-transparent"
                      style={{
                        borderRadius: "20px",
                        width: "150px",
                        margin: "0 auto",
                      }}
                      onClick={() => handleClickEdit(item)}
                    >
                      <img
                        src="/icons/adminstrator-icons/edit.svg"
                        alt=""
                        className=" col-4 editImg"
                      />
                      <span
                        className="col-8"
                        style={{ fontFamily: "SinewsSansPro" }}
                      >
                        {translationEnabled ? "Modifica info" : "Edit Info"}
                      </span>
                    </Button>
                  </div>
                  {/* <Button
                  variant="bg-transparent container"
                  onClick={() => handleDelete(item)}
                >
                  <div className="row badge badge-pill badge-light text-dark border border-dark m-2 edit modalHead">
                    <img
                      src="/icons/adminstrator-icons/edit.svg"
                      alt=""
                      className=" col-4 editImg"
                    />

                    <span className=" col-8">Delete</span>
                  </div>
                </Button> */}
                </div>
              );
            })}
        </div>

        <div className="row m-5">
          <Highlighter title="Wi-fi" fontColor={restData && restData.fontColor ? restData.fontColor : "#ffff"} />
        </div>
        <div className="row">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <AdminEditWifi
              wifiInputValue={wifiInputValue}
              setWifiInputValue={setWifiInputValue}
              handleAddEditRestaurant={handleAddEditRestaurant}
              editItem={editItem}
              handleClickEdit={handleClickEdit}
              allRestaurants={allRestaurants}
              handleSnackbarVal={handleSnackbarVal}
              getRestaurants={getRestaurants}
            />

            {disable ? (
              <>
                <Button
                  variant="bg-transparent"
                  onClick={handleDisable}
                  className="border border-dark borderRound modalHead"
                  style={{
                    marginRight: "5px",
                    padding: "0 5px",
                    fontFamily: "SinewsSansPro",
                    width: "150px",
                  }}
                >
                  <RemoveRedEyeOutlinedIcon className="me-2" />

                  {translationEnabled ? "Mostra wifi" : "Show Wifi"}
                </Button>{" "}
              </>
            ) : (
              <>
                <Button
                  variant="bg-transparent"
                  onClick={handleDisable}
                  className="border border-dark borderRound modalHead"
                  style={{
                    marginRight: "5px",
                    padding: "0 5px",
                    fontFamily: "SinewsSansPro",
                    width: "150px",
                  }}
                >
                  <RemoveRedEyeOutlinedIcon className="me-2" />

                  {translationEnabled ? "Nascondi wifi" : "Hide Wifi"}
                </Button>
              </>
            )}
          </div>

          {!disable && (
            <>
              <div className="mt-4 text-center">
                {allRestaurants.map((item) => {
                  return (
                    <Accordion
                      expanded={expanded === "wifi"}
                      onChange={handleChange("wifi")}
                      className={classes.customCollapse}
                      style={{
                        backgroundColor:
                          restData && restData.themeColor
                            ? restData.themeColor
                            : "#507839",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        style={{ textAlign: "left" }}
                      >
                        <div
                          className="container justify-content-start"
                          style={{
                            textTransform: "uppercase",
                            fontFamily: "SinewsSansPro",
                            color:
                              restData && restData.fontColor
                                ? restData.fontColor
                                : "#ffff",
                          }}
                        >
                          WI-FI GRATUITO
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="p-2">
                          <div className="col">
                            <img
                              src={"/icons/wi-fi-icon/wifi.svg"}
                              className="w-25 wifiIcon"
                              alt=""
                              style={{
                                border: "1px solid #00000020",
                                borderRadius: "10px",
                              }}
                            />
                          </div>

                          <div className="col">
                            <strong style={{ fontFamily: "SinewsSansPro" }}>
                              Servizio Gratuito
                            </strong>
                            <p
                              className="wifiCredentials"
                              style={{ fontFamily: "SinewsSansPro" }}
                            >
                              LINEA: {item.wifi_name}
                            </p>
                            <p
                              className="wifiCredentials"
                              style={{ fontFamily: "SinewsSansPro" }}
                            >
                              PASSWORD: {item.wifi_password}
                            </p>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </div>
            </>
          )}
        </div>
        <AdminMenuView />
      </Container>
    </div>
  );
}

export default Restaurant;
