import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import { Grid, TextField, InputLabel, Typography } from "@mui/material";
import { useStyles } from "./../globalStyles/globalStyles";
import axios from "axios";
import { auth_login } from "./../api/index";
import Loader from "./Loader";
import SnackbarAlert from "./snackbar/SnackbarAlert";

const Login = () => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState({
    email: "",
    password: "",
  });
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if(user?.email){
      window.location = "/"
    }
  },[])


  const handleLogin = () => {
    let { email, password } = inputValue;

    if (email == "" || password == "") {
      setSnackbarProps({
        open: true,
        type: "error",
        message: "All fields are required",
      });
    } else {
      setIsLoading(true);
      axios
        .post(auth_login, inputValue)
        .then((res) => {
          setIsLoading(false);
          setInputValue({
            email: "",
            password: "",
          });
        
          localStorage.setItem("user", JSON.stringify(res.data));

          window.location = "/admin";
        })
        .catch((err) => {
          setIsLoading(false);
          // alert(err.response.data.message);
          setSnackbarProps({
            open: true,
            type: "error",
            message: "Something went wrong",
          });
        });
    }
  };

  const onHandleChange = (e) => {
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });
  };

  const handleCloseSnackbar = () => {
    setSnackbarProps({
      open: false,
      type: "",
      message: "",
    });
  };

  return (
    <div>
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />

      <Loader isLoading={isLoading} />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <Grid item xs={12} sm={4}>
          <div className="header m-3 text-center">
            <img src={"/logo/logo-bump-smart-menu-2.png"} alt="" width="50%" />

            <Typography variant="h4" className={classes.loginHead}>
            ACCEDI
            </Typography>
          </div>
          <div className="content">
            {/* <div className="image">
            <img src={"/icons/adminstrator-icons/mobile-login.svg"} />
          </div> */}
            <div>
              {/* <label htmlFor="username">Username</label>
                <input type="text" name="username" placeholder="username" /> */}

              <InputLabel htmlFor="fName" className={classes.globalInputLabel}>
                Email
              </InputLabel>
              <TextField
                name="email"
                variant="outlined"
                size="small"
                value={inputValue.email}
                onChange={onHandleChange}
                className={classes.globalInput}
                fullWidth
              />
            </div>
            <div className="form-group">
              <InputLabel htmlFor="fName" className={classes.globalInputLabel}>
                Password
              </InputLabel>
              <TextField
                name="password"
                variant="outlined"
                size="small"
                type="password"
                value={inputValue.password}
                onChange={onHandleChange}
                className={classes.globalInput}
                fullWidth
              />
            </div>
          </div>
          <div
            className="footer"
            style={{ textAlign: "center", margin: "20px 0" }}
          >
            {/* <Link to={"/admin"}> */}
            <button
              type="button"
              onClick={handleLogin}
              className="btn btn-primary"
            >
              Accedi
              {/* Needs API call */}
            </button>
            {/* </Link> */}
          </div>

          <div className="col text-center my-3">
            <Link to="/registrati" className="link-secondary">
            Crea un Account?
            </Link>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;
