import { makeStyles } from "@mui/styles";
import "../assets/fonts/font.css";

export const useStyles = makeStyles((theme) => ({
  customCollapse: {
    "&.MuiPaper-root": {
      borderRadius: "20px !important",
    },
    "&.MuiPaper-root .MuiAccordionSummary-content": {
      alignItems: "center",
    },
    "&.MuiPaper-root .MuiAccordionSummary-root .MuiAccordionSummary-content": {
      minHeight: "45px",
      color: "#ffff",
      fontFamily: "SinewsSansPro !important",
      fontWeight: "500 !important",
    },
    "&.MuiPaper-root .MuiAccordionSummary-root .MuiAccordionSummary-expandIconWrapper":
      {
        color: "#ffff",
      },
    "&.MuiPaper-root .MuiCollapse-root": {
      background: "#fff !important",
    },
  },

  globalInputLabel: {
    fontSize: "13px !important",
    margin: "5px 0",
  },

  globalInput: {
    width: "100%",
    "&.MuiFormControl-root .MuiInputBase-root .MuiInputBase-input": {
      padding: "10.2px 12px !important",
      fontSize: "12px !important",
    },
    "&.MuiFormControl-root .MuiInputBase-root .MuiInputBase-input::placeholder":
      {
        fontSize: "12px !important",
      },
  },
  loginHead: {
    fontSize: "30px !important",
    marginTop: "20px !important",
    fontWeight: "bold !important",
  },
  listItemStyle: {
    "&.MuiListItem-root .MuiListItemText-root .MuiListItemText-primary": {
      fontFamily: "SinewsSansPro !important",
      fontSize: "14px",
    },
    "&.MuiListItem-root .MuiListItemIcon-root": {
      [theme.breakpoints.down(600)]: {
        minWidth: "35px !important",
      },
    },
    "&.MuiListItem-root .MuiListItemIcon-root svg": {
      [theme.breakpoints.down(600)]: {
        fontSize: "20px",
      },
    },
  },
}));
