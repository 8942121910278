import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style-sheets/AdminHeader.css";
import { Link } from "react-router-dom";
import SettingsModal from "./SettingsModal";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";

function Header() {
  const [show, setShow] = useState(false);

  const [translationEnabled, setTranslationEnabled] = useState(true);

  return (
    <div className="container-fluid my-2">
      <div className="row">
        <div className="col-7">
          <img
            src="/icons/adminstrator-icons/settings/00-setting-logo.svg"
            alt="Settings Icon 404"
            width={"30px"}
            className="me-3"
          />
          <a
            href="#"
            style={{ fontFamily: "SinewsSansPro" }}
            className="link-dark"
            onClick={() => setShow(true)}
          >
            {translationEnabled ? "Impostazioni" : "Settings"}
          </a>
        </div>

        {/* <div className="col-5"></div> */}
        <div
          className="col-5 d-flex flex-row-reverse"
          style={{ alignItems: "center" }}
        >
          {JSON.parse(localStorage.getItem("restData"))?.orderFromTable !=
          "false" ? (
            <Link
              to={"/AdminOrders"}
              // onClick={() => localStorage.clear()}
              className="text-dark"
              style={{ fontFamily: "SinewsSansPro" }}
            >
              {translationEnabled == true ? "Lista Ordini" : "Order List"}
              <span>
                <AssignmentOutlinedIcon className="me-2" fontSize="medium" />
              </span>
            </Link>
          ) : (
            <></>
          )}
        </div>
      </div>
      <SettingsModal show={show} setShow={setShow} />
    </div>
  );
}

export default Header;
