import React, { useEffect } from "react";
import AdminHeader from "../admin/AdminHeader";
import AdminRestaurant from "../admin/AdminRestaurant";

const AdminView = () => {
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    if (userData) {
    } else {
      window.location = "/login";
    }
  }, []);

  return (
    <div>
      <AdminHeader />
      <AdminRestaurant />
    </div>
  );
};

export default AdminView;
