import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style-sheets/AdminEditInfo.css";

import { Button, Modal } from "react-bootstrap";

function AdminEditInfo({
  handleAddEditRestaurant,
  inputValue,
  setInputValue,
  show,
  setShow,
  setType,
  type,
  allRestaurants,
}) {
  const [translationEnabled, setTranslationEnabled] = useState(true);

  const handleClose = () => {
    setShow(false);
    if (type == "add") {
      setInputValue({
        restaurantName: "",
        biography: "",
        streetAddress: "",
        telephone: "",
        instaLink: "",
        fbLink: "",
        whatsappNum: "",
        tripadivsorLink: "",
      });
    }
  };
  const handleShow = () => {
    setShow(true);
    setType("add");
  };

  const handleChange = (e) => {
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });
  };

  return (
    <>
      {allRestaurants.length > 0 ? null : (
        <Button variant="bg-transparent container" onClick={handleShow}>
          <div className="row badge badge-pill badge-light text-dark border border-dark m-2 edit modalHead">
            <img
              src="/icons/adminstrator-icons/edit.svg"
              alt=""
              className=" col-4 editImg"
            />

            <span className=" col-8" style={{ fontFamily: "SinewsSansPro" }}>
              Add Info
            </span>
          </div>
        </Button>
      )}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="border border-light modalBody"
        centered
      >
        <Modal.Header className="justify-content-start modalHead">
          <Button
            variant="border border-danger text-danger bg-light mx-auto ms-1"
            onClick={handleClose}
            style={{ fontFamily: "Burlingame" }}
          >
            {translationEnabled ? "Chiudi" : "Close"}
          </Button>
          <Modal.Title className="mx-auto text-secondary">
            {type == "add" ? "ADD INFO" :  translationEnabled ? "Info Attività" : "Edit Info"} 
          </Modal.Title>
          <Button
            variant="border border-primary text-primary bg-light mx-auto me-1"
            onClick={handleAddEditRestaurant}
          >
            {translationEnabled ? "Salva" : "Save"}
          </Button>
        </Modal.Header>
        <Modal.Body className="backColor border border-secondary">
          <div className="container">
            <form className="row g-3">
              <div className="col-12">
                <label for="restName" class="form-label">
                Nome Attività
                </label>
                <input
                  type="text"
                  value={inputValue.restaurantName}
                  name="restaurantName"
                  onChange={handleChange}
                  className="form-control border border-primary"
                  id="restName"
                  placeholder="Inserisci nome attività"
                />
              </div>

              <div className="col-12">
                {translationEnabled ? (
                  <>
                    <label for="biography" class="form-label">
                      Biografia
                    </label>
                    <textarea
                      type="text"
                      className="form-control border border-primary"
                      id="biography"
                      value={inputValue.biography}
                      name="biography"
                      onChange={handleChange}
                      placeholder="Inserisci biografia / orari / sconti"
                    ></textarea>
                  </>
                ) : (
                  <>
                    <label for="biography" class="form-label">
                      BIOGRAPHY
                    </label>
                    <textarea
                      type="text"
                      className="form-control border border-primary"
                      id="biography"
                      value={inputValue.biography}
                      name="biography"
                      onChange={handleChange}
                      placeholder="Insert biography / hours / discount"
                    ></textarea>
                  </>
                )}
              </div>

              <div className="col-12">
                {translationEnabled ? (
                  <>
                    {" "}
                    <label for="address" class="form-label">
                      Indirizzo
                    </label>
                    <input
                      type="text"
                      className="form-control border border-primary"
                      id="address"
                      value={inputValue.streetAddress}
                      name="streetAddress"
                      onChange={handleChange}
                      placeholder="Inserisci indirizzo attività"
                    />
                  </>
                ) : (
                  <>
                    {" "}
                    <label for="address" class="form-label">
                      Street Address
                    </label>
                    <input
                      type="text"
                      className="form-control border border-primary"
                      id="address"
                      value={inputValue.streetAddress}
                      name="streetAddress"
                      onChange={handleChange}
                      placeholder="Insert restaurant's street"
                    />
                  </>
                )}
              </div>

              <div className="col-12">
                {translationEnabled ? (
                  <>
                    <label for="telephone" class="form-label">
                      Telefono/Cellulare
                    </label>
                    <input
                      type="text"
                      className="form-control border border-primary"
                      id="telephone"
                      value={inputValue.telephone}
                      name="telephone"
                      onChange={handleChange}
                      placeholder="Inserisci telefono attività"
                    />
                  </>
                ) : (
                  <>
                    <label for="telephone" class="form-label">
                      TELEPHONE
                    </label>
                    <input
                      type="text"
                      className="form-control border border-primary"
                      id="telephone"
                      value={inputValue.telephone}
                      name="telephone"
                      onChange={handleChange}
                      placeholder="Insert restaurant's telephone"
                    />
                  </>
                )}
              </div>

              <div className="col-12">
                <label for="instagram" class="form-label">
                  INSTAGRAM
                </label>
                <input
                  type="text"
                  className="form-control border border-primary"
                  id="instagram"
                  value={inputValue.instaLink}
                  name="instaLink"
                  onChange={handleChange}
                  placeholder="Inserisci link profilo Instagram"
                />
              </div>

              <div className="col-12">
                <label for="facebook" class="form-label">
                  FACEBOOK
                </label>
                <input
                  type="text"
                  className="form-control border border-primary"
                  id="facebook"
                  value={inputValue.fbLink}
                  name="fbLink"
                  onChange={handleChange}
                  placeholder="Inserisci link profilo Facebook"
                />
              </div>

              <div className="col-12">
                <label for="whatsapp" class="form-label">
                  WHATSAPP
                </label>
                <input
                  type="text"
                  className="form-control border border-primary"
                  id="whatsapp"
                  value={inputValue.whatsappNum}
                  name="whatsappNum"
                  onChange={handleChange}
                  placeholder="Inserisci numero Whatsapp"
                />
              </div>

              <div className="col-12">
                <label for="tripadvisor" class="form-label">
                  TRIPADVISOR
                </label>
                <input
                  type="text"
                  className="form-control border border-primary"
                  id="tripadvisor"
                  value={inputValue.tripadivsorLink}
                  name="tripadivsorLink"
                  onChange={handleChange}
                  placeholder="Inserisci link profilo Tripadvisor"
                />
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AdminEditInfo;
