import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style-sheets/Allergen.css";

const allergens = [
  {
    id: 1,
    name: "celery",
    translatedName: "sedano",
    exclude: false,
  },
  {
    id: 2,
    name: "crustacens",
    translatedName: "crostacei",
    exclude: false,
  },
  {
    id: 3,
    name: "eggs",
    translatedName: "uova",
    exclude: false,
  },
  {
    id: 4,
    name: "fish",
    translatedName: "pesce",
    exclude: false,
  },
  {
    id: 5,
    name: "gluten",
    translatedName: "Glutine",
    exclude: false,
  },
  {
    id: 6,
    name: "lactose",
    translatedName: "lattosio",
    exclude: false,
  },
  {
    id: 7,
    name: "lupin",
    translatedName: "lupini",
    exclude: false,
  },
  {
    id: 8,
    name: "molluscs",
    translatedName: "molluschi",
    exclude: false,
  },
  {
    id: 9,
    name: "mustard",
    translatedName: "senape",
    exclude: false,
  },
  {
    id: 10,
    name: "peanut",
    translatedName: "arachidi",
    exclude: false,
  },
  {
    id: 11,
    name: "sesame",
    translatedName: "sesamo",
    exclude: false,
  },
  {
    id: 12,
    name: "soya",
    translatedName: "soia",
    exclude: false,
  },
  {
    id: 13,
    name: "sulphurdioxide",
    translatedName: "solfiti",
    exclude: false,
  },
  {
    id: 14,
    name: "treenut",
    translatedName: "frutta a guscio",
    exclude: false,
  },
];

function Allergen({ excludedAllergens , setExcludedAllergens}) {

  const [show, setShow] = useState(false);
  const [translatedAllergens, setTranslatedAllergens] = useState(excludedAllergens?.slice());

  const handleClose = () => {
    setShow(false)
  };

  const handleShow = () => setShow(true);

  useEffect(()=> {
    let temp = [...excludedAllergens];
    for(let i = 0; i<temp.length; i++){
      let foundAllergen = allergens.find((allergen) => allergen.name === temp[i].name);
      temp[i].translatedName = foundAllergen.translatedName;
    }
    setTranslatedAllergens(temp);
  },[excludedAllergens])

  const handleExcludeAllergen = (item, i) => {
    let tempArr = [...translatedAllergens];
    if (tempArr[i].exclude === false) {
      tempArr[i].exclude = true;
    } else tempArr[i].exclude = false;

    setTranslatedAllergens(tempArr);
  };

  const [translationEnabled, setTranslationEnabled] = useState(true);

  useEffect(() => {
    const translation = localStorage.getItem("translationEnabled");
    if(translation == "true"){
      setTranslationEnabled(true)
    }else if(translation == "false"){
      setTranslationEnabled(false)
    }else{
      setTranslationEnabled(true)
    }
  }, [])

  return (
    <div>
      <Button variant="btnAllergen" onClick={handleShow}>
        <img
          src={"/icons/allergen-icons/allergens-yellow/gluten.svg"}
          id="allergenIcon"
          width="25px"
          className="allergenIcon"
          alt="allergenIcon"
        />
      </Button>

      <Modal show={show} onHide={handleClose} centered>
        <div className="border border-warning text-warning">
          <Modal.Header style={{ display: "flex", justifyContent: "start" }}>
            <Button
              variant="border border-warning text-warning"
              onClick={() => {
                let items = JSON.parse(sessionStorage.getItem("excludedAllergens"))
                
                if(items){
                  setTranslatedAllergens(items);
                }else{
                  setTranslatedAllergens(allergens)
                }
               
                handleClose();
              }}
              style={{ fontFamily: "Burlingame" }}
            >
              {translationEnabled ? "Chiudi" : "Close"}
            </Button>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                paddingRight: "50px"
              }}
            >
              <Modal.Title>
                <div
                  className="ModalHeading text-warning"
                  style={{ fontFamily: "SinewsSansPro" }}
                >
                  {translationEnabled ? "Allergeni" : "ALLERGENS"}
                </div>
              </Modal.Title>
            </div>
          </Modal.Header>
        </div>
        <Modal.Body>
          <div className="text-center">
            <h6 style={{ fontFamily: "SinewsSansPro" }}>
              {translationEnabled
                ? "Seleziona gli allergeni da escludere"
                : "Select the allergens to exclude"}
            </h6>
            <div
              className="card m-2 border border-warning"
              style={{ padding: "10px 0" }}
            >
              <div className="allergenContainer">
                {translatedAllergens &&
                  translatedAllergens.map((allergen, ind) => {
                    return (
                      <Button
                        onClick={() => handleExcludeAllergen(allergen, ind)}
                        className="allergenCol badge"
                        style={{
                          backgroundColor: "white",
                          textTransform: "uppercase",
                          color: allergen.exclude ? "red" : "green",
                          fontFamily: "SinewsSansPro",
                          borderColor: allergen.exclude ? "red" : "green"
                        }}
                     
                      >
                        {!allergen.exclude ? (
                          <div>
                            <img
                              src={`/icons/allergen-icons/allergens-yellow/${allergen.name}.svg`}
                              width="25%"
                              style={{
                                marginRight: "5px",
                                // filter:
                                //   "invert(87%) sepia(67%) saturate(6028%) hue-rotate(347deg) brightness(107%) contrast(105%)",
                              }}
                            />
                          </div>
                        ) : (
                          <div>
                            <img
                              src={`/icons/allergen-icons/allergens-grey/${allergen.name}.svg`}
                              width="25%"
                              style={{
                                marginRight: "5px",
                                filter:
                                  "invert(22%) sepia(99%) saturate(7473%) hue-rotate(358deg) brightness(101%) contrast(113%)",
                              }}
                            />
                          </div>
                        )}
                        <span>{translationEnabled ? allergen.translatedName : allergen.name}</span>
                      </Button>
                    );
                  })}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <Button
            variant="warning text-light"
            onClick={() => {
              setExcludedAllergens(translatedAllergens.slice());
              sessionStorage.setItem(
                "excludedAllergens",
                JSON.stringify(translatedAllergens)
              );
              handleClose();
            }}
            style={{ fontFamily: "SinewsSansPro" }}
          >
            {translationEnabled ? "conferma" : "Confirm"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Allergen;
