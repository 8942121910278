import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style-sheets/Header.css";
import { Link } from "react-router-dom";

import Allergen from "./Allergen";
import Cart from "./Cart";

function Header({
  excludedAllergens,
  setExcludedAllergens,
  setSelectedProducts,
  selectedProducts,
}) {
  const restData = JSON.parse(sessionStorage.getItem("restData"));
  const [translationEnabled, setTranslationEnabled] = useState(true);

  useEffect(() => {
    const translation = localStorage.getItem("translationEnabled");
    if(translation == "true"){
      setTranslationEnabled(true)
    }else if(translation == "false"){
      setTranslationEnabled(false)
    }else{
      setTranslationEnabled(true)
    }
  }, [])

  return (
    <>
      <div
      className="header bg-light"
      style={{ width: "100%"}}
    >
      <div className="headerNav">
        <div>
         <a href="/">
          <img
              src={"/logo/logo-bump-smart-menu-2.png"}
              className="headerLogo"
              alt="Logo"
            />
         </a>
        </div>
        <div className="container-fluid justify-content-end options">
          {/* <div className="me-2">
            <Link to="/login">
              <LoginRoundedIcon
                fontSize="large"
                style={{ fontSize: "20px" }}
                className="text-secondary"
              />
            </Link>
          </div> */}
          <div>
            {restData?.orderFromTable == "true" ? (
              <Cart
                style={{ fontSize: "20px" }}
                setSelectedProducts={setSelectedProducts}
                selectedProductsState={selectedProducts}
           
              />
            ) : null}
          </div>
          <div>
            <Allergen
              excludedAllergens={excludedAllergens}
              setExcludedAllergens={setExcludedAllergens}
            />
          </div>
          <div onClick={() => {
            localStorage.setItem("translationEnabled", !translationEnabled)
            window.location.reload();
          }} style={{cursor: "pointer"}}>
           {translationEnabled ?  <img
              src={"/icons/flag-icons/italian-language.png"}
              className="headerIcons flag ms-2"
              width="20px"
              alt=""
            /> :  <img
            src={"/icons/flag-icons/english-language.png"}
            className="headerIcons flag ms-2"
            width="20px"
            alt=""
          />}
          </div>
        </div>
      </div>

      <hr />
    </div>
    <div
      className="header bg-light"
      style={{ position: "fixed", width: "100%", zIndex: 999, top: "0" }}
    >
      <div className="headerNav">
        <div>
        <a href="/">
          <img
              src={"/logo/logo-bump-smart-menu-2.png"}
              className="headerLogo"
              alt="Logo"
            />
         </a>
        </div>
        <div className="container-fluid justify-content-end options">
          {/* <div className="me-2">
            <Link to="/login">
              <LoginRoundedIcon
                fontSize="large"
                style={{ fontSize: "20px" }}
                className="text-secondary"
              />
            </Link>
          </div> */}
          <div>
            {restData?.orderFromTable == "true" ? (
              <Cart
                style={{ fontSize: "20px" }}
                setSelectedProducts={setSelectedProducts}
                selectedProductsState={selectedProducts}
              />
            ) : null}
          </div>
          <div>
            <Allergen
              excludedAllergens={excludedAllergens}
              setExcludedAllergens={setExcludedAllergens}
            />
          </div>
          <div onClick={() => {
            localStorage.setItem("translationEnabled", !translationEnabled)
            window.location.reload();
          }} style={{cursor: "pointer"}}>
           {translationEnabled ?  <img
              src={"/icons/flag-icons/italian-language.png"}
              className="headerIcons flag ms-2"
              width="20px"
              alt=""
            /> :  <img
            src={"/icons/flag-icons/english-language.png"}
            className="headerIcons flag ms-2"
            width="20px"
            alt=""
          />}
          </div>
        </div>
      </div>

      <hr />
    </div>
    </>
  );
}

export default Header;
