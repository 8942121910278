import React, { useEffect, useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style-sheets/AdminEditMenu.css";

import { Button, Modal } from "react-bootstrap";
import AdminPrice from "./AdminPrice";
import AdminAllergen from "./AdminAllergen";
import SnackbarAlert from "../snackbar/SnackbarAlert";

import AdminPhotoUpload from "./AdminPhotoUpload";
import dummyImage from "../../assets/add-photo.svg";
import { admin_add_product, admin_edit_product } from "../../api";
import Loader from "../Loader";
import axios from "axios";
import cameraImg from "../../assets/camera.svg";
import { FaceRetouchingNaturalOutlined } from "@mui/icons-material";
import { Switch } from "@mui/material";

var arraySort = require("array-sort");

const allergens = [
  {
    id: 1,
    name: "celery",
    translatedName: "sedano",
    exclude: false,
  },
  {
    id: 2,
    name: "crustacens",
    translatedName: "crostacei",
    exclude: false,
  },
  {
    id: 3,
    name: "eggs",
    translatedName: "uova",
    exclude: false,
  },
  {
    id: 4,
    name: "fish",
    translatedName: "pesce",
    exclude: false,
  },
  {
    id: 5,
    name: "gluten",
    translatedName: "Glutine",
    exclude: false,
  },
  {
    id: 6,
    name: "lactose",
    translatedName: "lattosio",
    exclude: false,
  },
  {
    id: 7,
    name: "lupin",
    translatedName: "lupini",
    exclude: false,
  },
  {
    id: 8,
    name: "molluscs",
    translatedName: "molluschi",
    exclude: false,
  },
  {
    id: 9,
    name: "mustard",
    translatedName: "senape",
    exclude: false,
  },
  {
    id: 10,
    name: "peanut",
    translatedName: "arachidi",
    exclude: false,
  },
  {
    id: 11,
    name: "sesame",
    translatedName: "sesamo",
    exclude: false,
  },
  {
    id: 12,
    name: "soya",
    translatedName: "soia",
    exclude: false,
  },
  {
    id: 13,
    name: "sulphurdioxide",
    translatedName: "solfiti",
    exclude: false,
  },
  {
    id: 14,
    name: "treenut",
    translatedName: "frutta a guscio",
    exclude: false,
  },
];

function AdminAddMenu({
  sectionId,
  setSectionId,
  type,
  setType,
  item,
  dialogData,
  setDialogData,
  show,
  setShow,
  getProducts,
  image,
  setImage,
  products,
  setProducts,
}) {
  const imageRef = useRef(null);
  const [addPriceField, setPriceField] = useState([{ price: 0, name: "" }]);
  const [isLoading, setIsLoading] = useState(false);
  const [allAlergens, setAllAlergens] = useState([]);
  const restData = JSON.parse(localStorage.getItem("restData"));
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [secId, setSecId] = useState(null);

  const user = JSON.parse(localStorage.getItem("user"));
  const [inputValue, setInputValue] = useState({
    name: "",
    description: "",
    price: [""],
  });
  const [productType, setProductType] = useState({
    spicy: false,
    frozen: false,
  });
  const [allergensData, setAllergensData] = useState(allergens);

  const onHandleChange = (e) => {
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });
  };

  const handleChangeProductType = (e) => {
    setProductType({ ...productType, [e.target.name]: e.target.checked });
  };

  const handleClose = () => {
    setDialogData({});
    setShow(false);
  };

  const [translationEnabled, setTranslationEnabled] = useState(true);

  useEffect(() => {
    if (sectionId) {
      setSecId(sectionId);
    }
  }, [sectionId]);

  useEffect(() => {
    const isEmpty = Object.keys(dialogData ? dialogData : {}).length === 0;

    if (type !== "add")
      if (!isEmpty) {
        if (dialogData?.name != undefined || dialogData?.desc != undefined) {
          setInputValue({
            name: dialogData?.name,
            description: dialogData?.desc,
          });
        }

        if (dialogData) {
          setSectionId(dialogData?.sectionId);
        }

        if (
          dialogData?.productType.spicy != undefined ||
          dialogData?.productType.frozen != undefined
        ) {
          setProductType({
            spicy: dialogData?.productType.spicy,
            frozen: dialogData?.productType.frozen,
          });
        }
        setImage(dialogData?.productUrl);
        if (dialogData?.price != undefined) {
          setPriceField(dialogData?.price);
        }
      }
  }, [dialogData, type]);

  useEffect(() => {
    if (type === "add") {
      setPriceField([{ price: 0, name: "" }]);
      setAllAlergens([]);
      setInputValue({
        name: "",
        description: "",
        price: [""],
      });
      setProductType({
        spicy: false,
        frozen: false,
      });
    }
  }, [type]);

  const addPrice = () => {
    let temp = [...addPriceField];
    temp.push({ price: 0, name: "" });
    setPriceField(temp);
  };

  const handleDelete = (index) => {
    let temp = [...addPriceField];
    temp.splice(index, 1);
    setPriceField(temp);
  };
  const handleChangeType = (e) => {};

  const handleChangePrice = (e, index) => {
    let tempArr = [...addPriceField];
    tempArr.map((item) => {
      tempArr[index].price = e.target.value;
    });
    setPriceField(tempArr);
  };

  const handleChangeName = (e, index) => {
    let tempArr = [...addPriceField];
    tempArr.map((item) => {
      tempArr[index].name = e.target.value;
    });
    setPriceField(tempArr);
  };

  const handleProduct = () => {
    let userData = JSON.parse(localStorage.getItem("user"));
    let { name, description, price } = inputValue;

    let data = allAlergens.filter((item) => {
      return item.active === true;
    });

    let myType = {
      spicy: false,
      forzen: false,
    };

    let params = {
      name,
      desc: description,
      price: addPriceField,
      productType,
      productUrl: image,
      productAllergens: data,
      sectionId: secId,
      restId: restData._id,
    };

    if (params.name == "") {
      setSnackbarProps({
        open: true,
        type: "error",
        message: "Name is required",
      });
    } else {
      setIsLoading(true);

      if (type == "add") {
        if (products.length == 0) {
          params.sequence = 1;
        } else {
          const result = arraySort(products, ["sequence"]);
          params.sequence = result.length;
        }

        axios
          .post(admin_add_product, params)
          .then((res) => {
            setSnackbarProps({
              open: true,
              type: "success",
              message: "Product created successfully",
            });
            setIsLoading(false);
            setInputValue({
              name: "",
              description: "",
              price: "",
              productType: "",
            });
            setPriceField([{ price: 0, name: "" }]);
            window.location.reload();
            setShow(false);
          })
          .catch((err) => {
            console.log(err, "error");
            setIsLoading(false);
            setSnackbarProps({
              open: true,
              message: err?.response?.data?.message,
              type: "error",
            });
            setShow(false);
          });
      } else {
        axios
          .put(`${admin_edit_product}/${dialogData._id}`, params)
          .then((res) => {
            let tempProducts = [...products];
            let newItem = { ...dialogData, ...params };

            for (let i = 0; i < tempProducts.length; i++) {
              if (tempProducts[i]._id == dialogData._id) {
                tempProducts[i] = newItem;
                break;
              }
            }

            console.log(dialogData, newItem);
            setProducts(tempProducts);

            setIsLoading(false);
            setSnackbarProps({
              open: true,
              type: "success",
              message: "Product updated successfully",
            });
            setInputValue({
              name: "",
              description: "",
              price: "",
              productType: "",
            });
            setPriceField([{ price: 0, name: "" }]);
            setAllAlergens([]);
            setShow(false);
            // window.location.reload();
          })
          .catch((err) => {
            console.log(err, "err");
            setIsLoading(false);
            setSnackbarProps({
              open: true,
              message: err?.response?.data?.message,
              type: "error",
            });
          });
      }
    }
  };

  const handleImage = async (e) => {
    setIsLoading(true);

    const form = new FormData();
    form.append("file", e.target.files[0]);
    form.append("upload_preset", "dvh3kj9z");
    try {
      let res = await axios.post(
        "https://api.cloudinary.com/v1_1/bump-smart-menu/upload",
        form
      );
      if (res) {
        setIsLoading(false);
        setImage(res.data.secure_url);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setSnackbarProps({
        open: true,
        message: error?.response?.data?.message,
        type: "error",
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarProps({
      open: false,
      type: "",
      message: "",
    });
  };

  return (
    <>
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Loader isLoading={isLoading} />

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="border border-light modalBody"
        centered
      >
        <Modal.Header className="justify-content-start modalHead">
          <Button
            variant="border border-danger text-danger bg-light mx-auto ms-1"
            onClick={handleClose}
            style={{ fontFamily: "Burlingame" }}
          >
            {translationEnabled ? "Chiudi" : "Close"}
          </Button>
          <Modal.Title
            className="mx-auto text-secondary"
            style={{ fontFamily: "SinewsSansPro" }}
          >
            {type == "add"
              ? translationEnabled
                ? "Agg. Prodotto"
                : "Add Product"
              : translationEnabled
              ? "Modifica Prodotto"
              : "Edit Product"}
          </Modal.Title>
          <Button
            variant="border border-primary text-primary bg-light mx-auto me-1"
            onClick={handleProduct}
            style={{ fontFamily: "Burlingame" }}
          >
            {translationEnabled ? "Salva" : "Save"}
          </Button>
        </Modal.Header>
        <Modal.Body className="backColor border border-secondary p-0">
          <div
            className="text-center"
            style={{ position: "relative", background: "#dfdfdf" }}
          >
            <button
              style={{
                position: "absolute",
                left: "10px",
                top: "20px",
                display: "flex",
                alignItems: "center",
                padding: "5px",
                borderRadius: "10px",
                fontStyle: "italic",
                color: "black",
                border: "2px solid",
              }}
              onClick={() => imageRef.current.click()}
            >
              <span style={{ marginRight: "5px" }}>
                <img src={cameraImg} width="100%" />
              </span>{" "}
              {translationEnabled ? "Cambia foto" : "Change Photo"}
            </button>

            <img
              src={image ? image : dummyImage}
              className="img-fluid restCover"
              style={{ width: "80%", marginTop: "40px" }}
            />
            <input
              type="file"
              onChange={handleImage}
              style={{ visibility: "hidden" }}
              // accept="image/png, image/gif, image/jpeg"
              ref={imageRef}
            />
          </div>
          <div className="container">
            <form className="row g-3 my-2">
              <div className="col-12">
                <label
                  for="restProduct"
                  className="form-label"
                  style={{ fontFamily: "SinewsSansPro" }}
                >
                  {translationEnabled ? "Nome prodotto" : "PRODUCT NAME"}
                </label>
                <input
                  name="name"
                  onChange={(e) => onHandleChange(e)}
                  type="text"
                  value={inputValue.name}
                  className="form-control border border-primary rounded"
                  id="restProduct"
                  placeholder={
                    translationEnabled
                      ? "Inserisci Nome Prodotto"
                      : "Insert Product Name"
                  }
                  style={{ fontFamily: "SinewsSansPro" }}
                />
              </div>

              <div className="col-12">
                <label
                  for="restDescription"
                  className="form-label"
                  style={{ fontFamily: "SinewsSansPro" }}
                >
                  {translationEnabled
                    ? "Descrizione prodotto"
                    : " PRODUCT DESCRIPTION"}
                </label>
                <input
                  name="description"
                  value={inputValue.description}
                  onChange={(e) => onHandleChange(e)}
                  type="text"
                  className="form-control border border-primary rounded"
                  id="restDescription"
                  placeholder={
                    translationEnabled
                      ? "Inserisci descrizione / ingredienti"
                      : "Insert Product Description / Ingredients"
                  }
                  style={{ fontFamily: "SinewsSansPro" }}
                />
              </div>

              <div className="col-12">
                <label
                  for="productPrice"
                  className="form-label"
                  style={{ fontFamily: "SinewsSansPro" }}
                >
                  {translationEnabled ? "Prezzo Prodotto" : "PRODUCT PRICE"}
                </label>

                {addPriceField?.map((item, index) => (
                  <span
                    className="my-2"
                    style={{ display: "flex", alignItems: "center" }}
                    key={index}
                  >
                    <input
                      type="text"
                      className="form-control border border-primary rounded w-50 mx-1"
                      id="productPrice"
                      placeholder={translationEnabled ? "Prezzo" : "Price"}
                      value={item.name}
                      onChange={(e) => handleChangeName(e, index)}
                      style={{ fontFamily: "SinewsSansPro" }}
                    />
                    <input
                      value={item.price}
                      type="number"
                      className="form-control border border-primary rounded me-1 w-25"
                      placeholder="5.00"
                      onChange={(e) => handleChangePrice(e, index)}
                      style={{ fontFamily: "SinewsSansPro" }}
                    />
                    {restData?.currency}
                    <img
                      onClick={() => handleDelete(index)}
                      src="/icons/adminstrator-icons/delete.svg"
                      className="menuImgDel"
                      alt=""
                      style={{
                        width: "20px",
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                    />
                  </span>
                ))}

                <Button
                  variant="border border-secondary bg-light mx-auto ms-1"
                  onClick={addPrice}
                  style={{ fontFamily: "SinewsSansPro" }}
                >
                  <img
                    src="/icons/adminstrator-icons/add-product-section.svg"
                    className=""
                    alt=""
                    style={{ marginRight: "5px" }}
                  />

                  {translationEnabled ? "Agg. Prezzo" : "Add Price"}
                </Button>
              </div>

              <div className="col-12" style={{ fontFamily: "SinewsSansPro" }}>
                {translationEnabled ? "Opzioni" : "Options"}
                <span className="row border border-primary mx-2 p-2 w-75 bg-light option1">
                  <AdminAllergen
                    dialogData={dialogData}
                    allergensData={allergensData}
                    setAllergensData={setAllergensData}
                    setAllAlergens={setAllAlergens}
                    allAlergens={allAlergens}
                  />
                </span>
                <div
                  className="border border-primary mx-2 p-2 w-75 bg-light"
                  style={{ display: "flex" }}
                >
                  <span>{translationEnabled ? "Piccante" : "Spicy"}</span>

                  <img
                    src="/icons/allergen-icons/spicy.svg"
                    className="spicyImg"
                    alt=""
                    style={{ width: "20px", marginLeft: "15px" }}
                  />
                  <div style={{ width: "100%", textAlign: "right" }}>
                    <Switch
                      name="spicy"
                      checked={productType.spicy}
                      onChange={(e) => handleChangeProductType(e)}
                      inputProps={{ "aria-label": "controlled" }}
                      value={productType.spicy}
                      size="small"
                    />
                  </div>
                </div>
                <div
                  className="border border-primary mx-2 p-2 w-75 bg-light option3"
                  style={{ display: "flex" }}
                >
                  <span>{translationEnabled ? "Surgelato" : "Frozen"}</span>
                  <img
                    src="/icons/allergen-icons/frozen.svg"
                    className="spicyImg"
                    alt=""
                    style={{ width: "20px", marginLeft: "15px" }}
                  />
                  <div style={{ width: "100%", textAlign: "right" }}>
                    <Switch
                      name="frozen"
                      checked={productType.frozen}
                      onChange={(e) => handleChangeProductType(e)}
                      inputProps={{ "aria-label": "controlled" }}
                      value={productType.frozen}
                      size="small"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AdminAddMenu;
